import apiClient from '../../../services/apiClient';
import apiConfig from '../../../services/apiConfig';

const baseUrl = `${apiConfig.getUrlSuprimentosApi()}/api`;

const getDefaultState = () => ({
  listaLabelNiveis: [],
});

const mutations = {
  atualizaLabelNiveis(state, payload) {
    state.listaLabelNiveis = payload;
  },
};

const actions = {
  async obterLabelNiveis({ commit }) {
    return new Promise((resolve, reject) => {
      const url = baseUrl + '/CadastrosAuxiliares/ListarLabelNiveis';

      apiClient
        .get(url)
        .then(({ data }) => {
          commit('atualizaLabelNiveis', data);
          return resolve(data);
        })
        .catch((erro) => reject(erro));
    });
  },
  async obterLocalizacoesDeposito(_, payload) {
    return new Promise((resolve, reject) => {
      const url = baseUrl + '/RequisicoesWMS/ObterLocalizacoesDeposito';
      const body = JSON.stringify(payload);

      apiClient
        .post(url, body)
        .then(({ data }) => resolve(data))
        .catch((erro) => reject(erro));
    });
  },
};

const getters = {
  listaLabelNiveis(state) {
    return state.listaLabelNiveis;
  },
};

export default {
  namespaced: true,
  getDefaultState,
  mutations,
  actions,
  getters,
};
