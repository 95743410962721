import Vue from 'vue';
import VueRouter from 'vue-router';

import routes from './routes';
import { authGuardMiddleware } from './middlewares';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  authGuardMiddleware(to, from, next);
});

export default router;
