import store from '../store/index';
function LoadingComponent() {}

LoadingComponent.prototype.ocultar = async function () {
  await store.dispatch('uiState/loadingController', -1);
};

LoadingComponent.prototype.exibir = async function () {
  await store.dispatch('uiState/loadingController', 1);
};

export default new LoadingComponent();
