export const getters = {
  isListaDeImportacoesFiltrada(state) {
    return state.listaDeImportacoes.length !== state.listaDeImportacoesFiltradas.length;
  },
  isProdutosImportacaoLoading(state, getters, rootState, rootGetters) {
    const { importacaoDeProdutos } = state.loading.loadingsPorComponente;
    return rootGetters['manageLoadingStates/is'](importacaoDeProdutos);
  },
  isImportacaoTableLoading(state, getters, rootState, rootGetters) {
    const { importacaoTable } = state.loading.loadingsPorComponente;
    return rootGetters['manageLoadingStates/is'](importacaoTable);
  },
  isImportacaoModalFormularioLoading(state, getters, rootState, rootGetters) {
    const { importacaoModalFormulario } = state.loading.loadingsPorComponente;
    return rootGetters['manageLoadingStates/is'](importacaoModalFormulario);
  },
  isImportacaoModalErrosLoading(state, getters, rootState, rootGetters) {
    const { importacaoModalErros } = state.loading.loadingsPorComponente;
    return rootGetters['manageLoadingStates/is'](importacaoModalErros);
  },
  labelLoading(state, getters, rootState, rootGetters) {
    const {
      buscarDadosIniciaisFormulario,
      obterDadosIniciaisPainelImportacoes,
      buscarInconsistencias,
      importarProdutosPorArquivo,
      cancelarImportacao,
      atualizarListagemPainelImportacoes,
      filtrarImportacoes,
      downloadArquivoComDivergencias,
    } = state.loading.nomesLoadingsPorAction;

    const isObtendoDadosIniciaisPainelImportacoes = rootGetters['manageLoadingStates/is'](obterDadosIniciaisPainelImportacoes);
    const isBuscarInconsistencias = rootGetters['manageLoadingStates/is'](buscarInconsistencias);
    const isImportarProdutosPorArquivo = rootGetters['manageLoadingStates/is'](importarProdutosPorArquivo);
    const isCancelandoImportacao = rootGetters['manageLoadingStates/is'](cancelarImportacao);
    const isAtualizandoListagemPainelImportacoes = rootGetters['manageLoadingStates/is'](atualizarListagemPainelImportacoes);
    const isFiltrandoImportacoes = rootGetters['manageLoadingStates/is'](filtrarImportacoes);
    const isBuscandoDadosIniciaisFormulario = rootGetters['manageLoadingStates/is'](buscarDadosIniciaisFormulario);

    const isBaixandoArquivoComDivergencias = rootGetters['manageLoadingStates/is'](downloadArquivoComDivergencias);

    if (isCancelandoImportacao) return 'Cancelando importação...';
    if (isBaixandoArquivoComDivergencias) return 'Baixando produtos divergentes...';
    if (isBuscarInconsistencias) return 'Buscando inconsistências...';
    if (isAtualizandoListagemPainelImportacoes) return 'Buscando lista de importações...';
    if (isFiltrandoImportacoes) return 'Pesquisando importações...';
    if (isObtendoDadosIniciaisPainelImportacoes) return 'Buscando dados do formulário...';
    if (isImportarProdutosPorArquivo) return 'Iniciando importação de produtos...';
    if (isBuscandoDadosIniciaisFormulario) return 'Buscando dados do formulário...';

    return 'Aguarde...';
  },
};
