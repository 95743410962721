export const formatarValorMonetario = (valor) => {
  if (!valor) return 'R$ 0,00';

  return (
    'R$ ' +
    new Intl.NumberFormat('pt-BR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(parseFloat(valor))
  );
};

export const formatarValor = (valor, qtdeDecimais) => {
  if (!qtdeDecimais) qtdeDecimais = 2;

  return new Intl.NumberFormat('pt-BR', {
    maximumFractionDigits: qtdeDecimais,
    minimumFractionDigits: qtdeDecimais,
  }).format(parseFloat(valor));
};

export const arredondarValorMonetario = (valor, digits) => {
  return Number(parseFloat(valor).toFixed(digits));
};

export const arredondarValorToEven = (number, places) => {
  const mult = Math.pow(10, places);

  return Math.round(number * mult) / mult;
};

export const decimalKeepPlaces = (value, decimalPlaces) => {
  if (parseFloat(value) == parseInt(value) && !isNaN(value)) return value;

  return value.toFixed(decimalPlaces).replace('.', ',');
};

export const allowOnlyNumbers = (event) => {
  var code = event.keyCode ? event.keyCode : event.which;

  if ((code < 37 || (code > 57 && code < 96) || code > 105) && code !== 8 && code !== 9) event.preventDefault();
};

export const truncateDecimals = (value, decimalPlaces) => {
	const mult = Math.pow(10, decimalPlaces);

	return Math.floor(value * mult) / mult;
};

export default {
  moneyRound(value) {
    if (!value) {
      return 0;
    }

    return Number(parseFloat(value).toFixed(2));
  },

  isNull(value, newValue) {
    if (value) {
      return value;
    }

    return newValue;
  },
};
