import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import VueTheMask from 'vue-the-mask';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import './assets/css/vue-multiselect.min.css';
import './assets/css/v-tooltip.min.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import VueWait from 'vue-wait';

library.add(faUserSecret);

Vue.config.productionTip = false;
Vue.use(VueSweetalert2);
Vue.use(VueWait);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueTheMask);
Vue.use(VueMoment, { moment });

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

const requireComponent = require.context('./components/shared', false, /Base[A-Z]\w+\.(vue|js)$/);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);

  const componentName = upperFirst(camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1')));

  Vue.component(componentName, componentConfig.default || componentConfig);
});

const wait = new VueWait({
  accessorName: '$suspense',
  useVuex: true,
  vuexModuleName: 'manageLoadingStates',
  registerComponent: true,
  componentName: 'Suspense',
  registerDirective: true,
  directiveName: 'suspense',
});

new Vue({
  router,
  store,
  wait,
  render: (h) => h(App),
}).$mount('#app');
