export const PRODUTOS_API = {
  ES: 'LMX.ES.WebApi',
  NFE: 'LMX.API.NFe 4.0',
  CRM: 'LMX.CRM.WebApi',
  Fiscal: 'LMX.Fiscal.WebApi',
  Faturamento: 'LMX.Faturamento.WebApi',
  AdmFinanceiro: 'LMX.AdmFinanceiro.WebApi',
  LIS: 'LinxMicrovix.WebApi.LIS',
  Suprimentos: 'LMX.Suprimentos.WebApi',
  ProdutosImagens: 'LMX.Produtos.Imagens.WebApi',
  ConnectHub: 'LMX.ConnectHub.WebApi'
};

export const NOMES_PRODUTOS_API = [
  PRODUTOS_API.ES,
  PRODUTOS_API.NFE,
  PRODUTOS_API.CRM,
  PRODUTOS_API.Fiscal,
  PRODUTOS_API.Faturamento,
  PRODUTOS_API.AdmFinanceiro,
  PRODUTOS_API.LIS,
  PRODUTOS_API.Suprimentos,
  PRODUTOS_API.ProdutosImagens,
  PRODUTOS_API.ConnectHub
];
