import Vue from 'vue';
import loading from '@/common/loading';
import string from './string';

function exibirMensagem() {
  this.tipoMensagem = { Sucesso: 1, Erro: 2, Atencao: 3 };
}

var _obterMensagemLista = function (listaMensagem) {
  var mensagem = '';

  if (Array.isArray(listaMensagem)) {
    mensagem = listaMensagem.join('</br>');
  } else {
    mensagem = listaMensagem.toString();
  }

  return mensagem;
};

exibirMensagem.prototype.exibirPergunta = function (titulo, mensagem, funcCallback) {
  return new Promise((resolve, reject) => {
    if (!funcCallback) funcCallback = function () {};

    Vue.swal({
      title: titulo,
      html: mensagem,
      showCloseButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-primary mr-1',
        cancelButton: 'btn btn-secondary',
      },
      buttonsStyling: false,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(function (result) {
      if (result.value === true) funcCallback(true);
      else if (result.dismiss === 'cancel') funcCallback(false);

      resolve(result.value);
    });
  });
};

exibirMensagem.prototype.exibirMensagemSucesso = function (mensagem, funcaoSucesso, mostraToast) {
  this.exibirMensagem(mensagem, this.tipoMensagem.Sucesso, funcaoSucesso, mostraToast);
};

exibirMensagem.prototype.exibirToastSucesso = function (mensagem, callback) {
  this.exibirMensagem(mensagem, this.tipoMensagem.Sucesso, callback, true);
};

exibirMensagem.prototype.exibirToastAlerta = function (mensagem, callback) {
  this.exibirMensagem(mensagem, this.tipoMensagem.Atencao, callback, true);
};

exibirMensagem.prototype.exibirMensagemErro = function (mensagem, funcaoSucesso, mostraToast) {
  this.exibirMensagem(mensagem, this.tipoMensagem.Erro, funcaoSucesso, mostraToast);
};

exibirMensagem.prototype.exibirListaMensagemErro = function (listaMensagem, funcaoSucesso, mostraToast) {
  var mensagem = _obterMensagemLista(listaMensagem);

  this.exibirMensagem(mensagem, this.tipoMensagem.Erro, funcaoSucesso, mostraToast);
};

exibirMensagem.prototype.exibirListaMensagemAlerta = function (listaMensagem, funcaoSucesso, mostraToast) {
  var mensagem = _obterMensagemLista(listaMensagem);

  this.exibirMensagem(mensagem, this.tipoMensagem.Atencao, funcaoSucesso, mostraToast);
};

exibirMensagem.prototype.exibirListagemMensagemAlerta = function (mensagem, funcaoSucesso, mostraToast) {
  this.exibirMensagem(mensagem, this.tipoMensagem.Atencao, funcaoSucesso, mostraToast);
};

exibirMensagem.prototype.exibirMensagemAlerta = function (mensagem, funcaoSucesso, mostraToast) {
  this.exibirMensagem(mensagem, this.tipoMensagem.Atencao, funcaoSucesso, mostraToast);
};

exibirMensagem.prototype.exibirMensagemInputWhatsapp = async function (title, numeroTelefonico, funcaoSucesso) {
  if (!numeroTelefonico) numeroTelefonico = '';

  await Vue.swal({
    title: title,
    html:
      '<input type="tel" id="swal-input1-whatsapp" name="swal-input1-whatsapp" class="swal2-input no-arrow" value="' +
      numeroTelefonico +
      '">',
    showCloseButton: true,
    showCancelButton: true,
    customClass: {
      confirmButton: 'btn btn-primary mr-1',
      cancelButton: 'btn btn-secondary',
    },
    buttonsStyling: false,
    confirmButtonText: 'Enviar',
    cancelButtonText: 'Cancelar',
    showLoaderOnConfirm: true,
    reverseButtons: false,
    preConfirm: () => {
      return document.getElementById('swal-input1-whatsapp').value;
    },
  }).then((result) => {
    if (result.value) {
      if (funcaoSucesso) {
        funcaoSucesso(result.value);
      }
    }
  });
};

exibirMensagem.prototype.exibirMensagem = function (
  mensagem,
  tipoMensagem,
  funcaoSucesso,
  mostraToast,
  tituloCustomizado,
  tamanho,
) {
  let tipo = '';
  let titulo = '';

  let propsSwal = {
    html: mensagem,
    confirmButtonText: 'Fechar',
    customClass: {
      confirmButton: 'btn btn-primary mr-1',
      cancelButton: 'btn btn-secondary',
    },
    buttonsStyling: false,
  };

  if (mostraToast === undefined) mostraToast = false;

  if (tipoMensagem === this.tipoMensagem.Sucesso) {
    tipo = 'success';
    titulo = 'Legal!!';
  } else if (tipoMensagem === this.tipoMensagem.Erro) {
    tipo = 'error';
    titulo = 'Oops!!';
  } else if (tipoMensagem === this.tipoMensagem.Atencao) {
    tipo = 'warning';
    titulo = 'Atenção';
  }

  if (mostraToast) {
    propsSwal.showCancelButton = false;
    propsSwal.showConfirmButton = false;
    propsSwal.toast = true;
    propsSwal.position = 'top';
    propsSwal.timer = 3000;
  }

  if (!mostraToast) propsSwal.allowOutsideClick = false;

  if (tamanho) {
    propsSwal.width = tamanho.width;
  }

  propsSwal.icon = tipo;
  propsSwal.title = tituloCustomizado || titulo;

  Vue.swal(propsSwal).then(function (result) {
    if (funcaoSucesso) {
      funcaoSucesso(result);
    }
  });
};

exibirMensagem.prototype.exibirMensagemValidacaoApi = function (retorno) {
  loading.ocultar();
  Vue.swal({
    icon: 'warning',
    title: 'Atenção',
    html: retorno.join('<br />'),
    confirmButtonText: 'Fechar',
    customClass: {
      confirmButton: 'btn btn-primary mr-1',
      cancelButton: 'btn btn-secondary',
    },
    allowOutsideClick: false,
    buttonsStyling: false,
  });
};

exibirMensagem.prototype.exibirMensagemErroApi = function (status, message, stack) {
  loading.ocultar();
  Vue.swal({
    icon: 'error',
    title: 'Atenção',
    html: 'Aconteceu algo inesperado.',
    confirmButtonText: 'Fechar',
    customClass: {
      confirmButton: 'btn btn-primary mr-1',
      cancelButton: 'btn btn-secondary',
    },
    allowOutsideClick: false,
    buttonsStyling: false,
  });
};

exibirMensagem.prototype.exibirMensagemComPergunta = function (mensagem, pergunta, funcCallback) {
  return new Promise((resolve, reject) => {
    if (!funcCallback) funcCallback = function () {};

    Vue.swal({
      title: '',
      html: `<div id="swal2-content" class="swal2-html-container" style="display: block;"><p class="font-weight-bold">${mensagem}</p><p>${pergunta}</p></div>`,
      showCloseButton: true,
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-primary mr-1',
        cancelButton: 'btn btn-secondary',
      },
      buttonsStyling: false,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(function (result) {
      if (result.value === true) {
        funcCallback(true);
      } else if (result.dismiss === 'cancel') funcCallback(false);

      resolve(result.value);
    });
  });
};

export default new exibirMensagem();
