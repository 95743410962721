<template>
  <div class="d-flex flex-column">
    <label class="input-required mb-1">Arquivo Preenchido</label>
    <VueDropzone
      ref="myVueDropzone"
      id="dropzone"
      :options="dropzoneOptions"
      :use-custom-slot="true"
      :include-styling="false"
      v-on:vdropzone-file-added="validarArquivoOnFileAdded"
      v-on:vdropzone-max-files-exceeded="verificarQuantidadeArquivos"
      v-on:vdropzone-removed-file="verificarArquivoRemovido"
      v-on:vdropzone-upload-progress="uploadprogress"
      v-on:vdropzone-success="disparaEventoImportarArquivo"
      class="d-flex justify-content-center align-items-center upload-action-certificado flex-grow-1"
    >
      <div v-show="mostraTextoDropzone" class="dropzone-custom-div">
        <span style="color: #777">Arraste seu arquivo ou clique aqui</span>
      </div>
    </VueDropzone>
  </div>
</template>

<script>
  import VueDropzone from 'vue2-dropzone';
  import swal from '@/common/alerts';
  import { EventBus } from '@/common/eventBus'

  export default {
    name: 'ImportacaoUpload',
    props: {
      maxFiles: {
        type: Number,
        default: 1,
      },
      maxFileSize: {
        type: Number,
        default: 0,
      },
      fileType: {
        type: String,
        default: '',
      },
    },
    components: {
      VueDropzone,
    },
    data() {
      return {
        mostraTextoDropzone: true,
        dropzoneOptions: {
          url: 'https://httpbin.org/post', //URL meramente ilustrativa, o componente exige que uma URL seja informada.
          maxFiles: 1,
          addRemoveLinks: true,
          dictRemoveFile: 'Remover arquivo',
          dictCancelUpload: 'Cancelar upload',
          dictCancelUploadConfirmation: 'Você tem certeza que quer cancelar este upload?',
          previewTemplate: this.template(),
          autoProcessQueue: false
        },
      };
    },
    methods: {
      verificarTipoArquivo(file) {
        this.mostraTextoDropzone = false;

        if (file.type != this.fileType) {
          swal.exibirMensagemErro('Apenas arquivos com a extensão .xls ou .xlsx são aceitos');
          this.$refs.myVueDropzone.removeFile(file);
          this.mostraTextoDropzone = true;
        }
      },
      verificarTamanhoNomeArquivo(file) {
        if (file.name.length > 80) {
          swal.exibirMensagemErro('O nome do arquivo não deve possuir mais que 80 caracteres');
          this.$refs.myVueDropzone.removeFile(file);
          this.mostraTextoDropzone = true;
        }
      },
      validarArquivoOnFileAdded(file) {
        this.verificarTipoArquivo(file);
        this.verificarTamanhoNomeArquivo(file);
        this.simularUploadESetarArquivo(file);
      },
      simularUploadESetarArquivo(file){
        setTimeout(() => {
          for(let i = 0; i < 100; i++)
            this.uploadprogress(file, i)
          this.disparaEventoImportarArquivo();
        }, 500)
      },
      verificarQuantidadeArquivos(file) {
        swal.exibirMensagemErro('Você deve fazer o upload de apenas um arquivo.');
        this.$refs.myVueDropzone.removeFile(file);
        this.mostraTextoDropzone = false;
      },
      verificarArquivoRemovido() {
        this.mostraTextoDropzone = true;
        EventBus.$emit('remover-arquivo');
      },
      uploadprogress(file, progress) {
        var progressElement = file.previewElement.querySelector('[aria-valuemin]');
        if (file.previewElement) progressElement.style.width = progress + '%';
      },
      disparaEventoImportarArquivo() {
        if(this.$refs.myVueDropzone){
          var file = this.$refs.myVueDropzone.getAcceptedFiles();
          swal.exibirToastSucesso('O upload foi realizado com sucesso');
          this.$emit('result-arquivo-upload', file);
        }
      },
      removeArquivoManualmente() {
        if (this.$refs.myVueDropzone) {
          var file = this.$refs.myVueDropzone.getAcceptedFiles();
          file[0] && this.$refs.myVueDropzone.removeFile(file[0]);
        }
      },
      template() {
        return '<div class="dz-preview dz-file-preview d-flex flex-column">\
                              <div class="dz-image">\
                                  <div data-dz-thumbnail-bg></div>\
                              </div>\
                              <div class="d-flex">   \
                                  <div>\
                                  <a class="dz-remove mr-2" href="javascript:undefined;" data-dz-remove="">\
                                      <i aria-hidden="true" class="fa fa-trash"></i>\
                                  </a>\
                                  </div>\
                                  <div class="dz-details">\
                                      <div class="dz-filename"><span data-dz-name></span></div>\
                                  </div>\
                              </div>\
                              <div class="progress" id="carregando">\
                                  <div class="progress-bar" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>\
                              </div>\
                            </div>';
      },
    },
    beforeDestroy() {
      this.removeArquivoManualmente();
    },
  };
</script>

<style scoped>
  .dropzone-custom-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .upload-action-certificado {
    border: 2px dashed #5b2e90;
    border-radius: 0px 0px 4px 4px;
    height: 120px;
    cursor: pointer;
    transition: background 0.6s ease;
  }

  #dropzoneIcon {
    color: #777;
    width: 35px;
    height: 35px;
  }

  #drozoneTexto {
    font-size: 14px;
  }

  .upload-action-certificado:hover {
    background-color: rgba(224, 224, 224, 0.3);
  }
</style>
