import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr';
import apiConfig from '@/services/apiConfig';

export default class ConnectHub {
  constructor(payload) {
    const urlHub = `${apiConfig.getUrlConnectHubApi()}/${payload.urlHub}`;
    this.connection = new HubConnectionBuilder()
      .withUrl(urlHub, { accessTokenFactory: () => payload.tokenJWT, skipNegotiation: true, transport: HttpTransportType.WebSockets })
      .configureLogging(LogLevel.Debug)
      .build();
  }
}
