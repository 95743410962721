import * as signalR from 'signalr';
import connectHub from '@/connectHubSignalR';

export const mutations = {
	updateQueryFiltroListagem(state, value) {
		state.queryFiltroListagem = value;
	},
	updateListaDeImportacoes(state, value) {
		state.listaDeImportacoes = value;
	},
	updateImportacaoSelecionada(state, value) {
		state.importacaoSelecionada = value;
	},
	updateSituacaoImportacaoSelecionada(state, value) {
		state.importacaoSelecionada.IdSituacaoImportacao = value;
	},
	updateListaDeImportacoesFiltrada(state, value) {
		state.listaDeImportacoesFiltradas = value;
	},
	updateStatusImportacao(state, value) {
		//debugger;
		let importacaoParaAtualizar = state.listaDeImportacoes.find((importacao) => importacao.Id == value.idImportacaoProdutos);

		if (importacaoParaAtualizar) {
			importacaoParaAtualizar.QtdeRegistrosCriados = value.produtosCriados;
			importacaoParaAtualizar.QtdeRegistrosAlterados = value.produtosAlterados;
			importacaoParaAtualizar.QtdeRegistrosDivergentes = value.produtosComDivergencias;
			importacaoParaAtualizar.TotaldeRegistros = value.produtosProcessados;
			importacaoParaAtualizar.IdSituacaoImportacao = value.idSituacao;
		}
	},
	updateHubConnectionToStop(state) {
		state.hubConnection.connection.stop();
	},
	setInitialConfigHubConnection(state, payload) {
		state.hubConnection = new connectHub(payload);
	},
	updateIsShowModalErrosToOpen(state) {
		state.isShowModalErros = true;
	},
	updateIsShowModalErrosToClosed(state) {
		state.isShowModalErros = false;
	},
	updateIsShowModalFormularioToOpen(state) {
		state.isShowModalFormulario = true;
	},
	updateIsShowModalFormularioToClosed(state) {
		state.isShowModalFormulario = false;
	},
};
