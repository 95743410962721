<template>
  <label :for="uuid" v-if="label">
    {{ labelFormatada }}
    <input
      v-bind="$attrs"
      :value="value"
      :placeholder="placeHolder"
      @input="onInput"
      class="form-control"
      :id="uuid"
      :aria-describedby="`${uuid}-descricao-auxiliar`"
      :aria-invalid="erro ? true : null"
      :name="label"
    />
    <p class="descricao-auxiliar" :id="`${uuid}-descricao-auxiliar`">
      {{ descricaoAuxiliar }}
    </p>
    <p v-if="erro" class="mensagem-erro" :id="`${uuid}-erro`" aria-live="assertive">
      {{ erro }}
    </p>
  </label>
</template>

<script>
  export default {
    name: 'BaseInput',
    props: {
      label: {
        type: String,
        default: '',
      },
      value: {
        type: [String, Number],
        default: '',
      },
      erro: {
        type: String,
        default: '',
      },
      campoObrigatorio: {
        type: Boolean,
        default: false,
      },
      placeHolder: {
        type: String,
        default: '',
      },
      descricaoAuxiliar: {
        type: String,
        default: '',
      },
      id: {
        type: String,
        default: 'input',
      },
    },
    data() {
      return {
        uuid: 0,
      };
    },
    computed: {
      labelFormatada() {
        return this.campoObrigatorio ? `${this.label} *` : this.label;
      },
    },
    methods: {
      onInput(event) {
        this.$emit('input', event.target.value);
      },
      getID() {
        var uuid = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
          (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
        );

        this.uuid = `${this.id}`;
      },
    },
    mounted() {
      this.getID();
    },
  };
</script>

<style scoped>
  label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 18px;
    margin: 0;
  }

  input {
    width: 100%;
  }

  .mensagem-erro {
    font-size: 14px;
    color: red;
    padding: 0;
    margin: 0;
  }

  .descricao-auxiliar {
    font-size: 14px;
    margin: 0 0 2px 0;
  }
</style>
