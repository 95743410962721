import axios from 'axios';
import apiConfig from './apiConfig';
import store from '@/store/index';

const apiClient = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  },
  withCredentials: false,
});

const HTTP_UNAUTHORIZED = 401;
const HTTP_BADREQUEST = 400;

apiClient.interceptors.request.use(
  (config) => {
    const token =
      store && store.getters['autenticacao/ehAutenticacaoAnonima'] ? apiConfig.getTokenAnonimo() : apiConfig.getToken();
    if (token && config.headers['Authorization'] == null) config.headers['Authorization'] = token;

    return config;
  },

  (error) => {
    return Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      return Promise.reject(error);
    }

    if (error.response.status === HTTP_BADREQUEST) {
      if (error.response.data && error.response.data.length === 1) return Promise.reject(error.response.data[0]);
      return Promise.reject(error);
    }

    // Se erro BadRequest e data possuí lenght = 1 retorna string mensagem de erro.
    //

    return Promise.reject(error);
  },
);

export default apiClient;
