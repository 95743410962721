import * as signalR from 'signalr';
import swal from '@/common/alerts';
import dateHelper from '@/common/date';
import { baixarArquivoExcel } from '@/common/files';

import apiClient from '@/services/apiClient';
import apiConfig from '@/services/apiConfig';

// const apiConfig.getUrlSuprimentosApi() = 'http://localhost:57385/api';
// const apiConfig.getUrlSuprimentosApi() = apiConfig.getUrlSuprimentosApi();

export const actions = {
	async obterDadosIniciaisProduto() {
		const url = `${apiConfig.getUrlSuprimentosApi()}/api/CadastroProdutos/ObterDadosIniciaisCadastroProdutos`;
		const { data } = await apiClient.get(url);
		return data;
	},
	async obterArquivoModelo() {
		const url = `${apiConfig.getUrlSuprimentosApi()}/api/ImportacaoProdutos/DownloadArquivoModelo`;
		const { data } = await apiClient.get(url);
		baixarArquivoExcel(data);
		return data;
	},
	async buscarDadosIniciaisFormulario() {
		const url = `${apiConfig.getUrlSuprimentosApi()}/api/ImportacaoProdutos/BuscarDadosIniciaisFormulario`;
		const { data } = await apiClient.get(url);
		return data;
	},
	async obterDadosIniciaisPainelImportacoes({ commit }) {
		try {
			const url = `${apiConfig.getUrlSuprimentosApi()}/api/ImportacaoProdutos/BuscarDadosIniciaisPainelImportacaoProdutos`;
			const { data } = await apiClient.get(url);
			commit('updateListaDeImportacoes', data.ListaImportacoesProdutos);
			commit('updateListaDeImportacoesFiltrada', data.ListaImportacoesProdutos);
			commit('updateQueryFiltroListagem', '');
			return data;
		} catch (error) {
			swal.exibirListaMensagemErro(error);
		}
	},
	async downloadArquivoComDivergencias({ state }) {
		const url = `${apiConfig.getUrlSuprimentosApi()}/api/ImportacaoProdutos/DownloadArquivoComDivergencias?IdImportacaoProdutos=${
			state.importacaoSelecionada.Id
		}`;
		const { data } = await apiClient.get(url);
		baixarArquivoExcel(data);

		return data;
	},
	async buscarInconsistencias({ state }) {
		const url = `${apiConfig.getUrlSuprimentosApi()}/api/ImportacaoProdutos/BuscarDadosDivergentesImportacaoProdutos?IdImportacaoProdutos=${
			state.importacaoSelecionada.Id
		}`;
		const { data } = await apiClient.get(url);
		return data;
	},
	async importarProdutosPorArquivo({ dispatch, commit }, payload) {
		const url = `${apiConfig.getUrlSuprimentosApi()}/api/ImportacaoProdutos/ImportarProdutosPorArquivo`;
		const { data } = await apiClient.post(url, JSON.stringify(payload));

		await dispatch('obterDadosIniciaisPainelImportacoes');
		commit('updateIsShowModalFormularioToClosed');

		return data;
	},
	async cancelarImportacao({ state }) {
		const url = `${apiConfig.getUrlSuprimentosApi()}/api/ImportacaoProdutos/CancelarImportacaoProdutos`;
		const { data } = await apiClient.post(
			url,
			JSON.stringify({
				Identificador: state.importacaoSelecionada.IdentificadorCancelamento,
			}),
		);
		return data;
	},
	async conectarHubProdutosImportacao({ state, commit, rootState }) {
		commit('setInitialConfigHubConnection', {
			tokenJWT: rootState.autenticacao.tokenJWT,
			urlHub: 'produtoshub/importacao',
		});

		let _hubConnection = state.hubConnection.connection;

		_hubConnection.on('AtualizarStatusImportacao', (payload) => commit('updateStatusImportacao', payload));
		_hubConnection.on('Joined', (payload) => console.log(payload));

		//debugger;
		try {
			await _hubConnection.start().then(() => {
				console.log('Connectado ao hub!');
				let groupName = rootState.autenticacao.empresa.Portal.toString();
				_hubConnection.invoke('JoinGroup', groupName);
			});
		} catch (error) {
			console.log(error);
			swal.exibirMensagemErro('Não foi possível abrir uma conexão com o Hub!');
		}
	},
	async atualizarListagemPainelImportacoes({ dispatch, commit }) {
		commit('updateHubConnectionToStop');
		await dispatch('conectarHubProdutosImportacao');
		await dispatch('obterDadosIniciaisPainelImportacoes');
	},
	async filtrarImportacoes({ state, commit }) {
		function filtrarImportacoesAsync(state, commit) {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					if (state.queryFiltroListagem.trim() === '') {
						commit('updateListaDeImportacoesFiltrada', state.listaDeImportacoes);
						return resolve();
					}

					const listaDeImportacoes = [...state.listaDeImportacoes];

					const listaDeImportacoesFiltradas = listaDeImportacoes.filter((importacao) => {
						const importacaoFiltrada = Object.keys(importacao)
							.map((prop) => {
								if (prop == 'NomeArquivo') return importacao[prop];
								if (prop == 'DataCriacao') return dateHelper.toStringPtBr(importacao[prop]);
								if (prop == 'SituacaoImportacao') return importacao[prop];
							})
							.toString()
							.toLowerCase()
							.includes(state.queryFiltroListagem.toLowerCase().trim());

						if (importacaoFiltrada) return importacao;
					});

					commit('updateListaDeImportacoesFiltrada', listaDeImportacoesFiltradas);
					return resolve();
				}, 300);
			});
		}

		await filtrarImportacoesAsync(state, commit);
	},
};
