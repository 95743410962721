import store from '@/store/index';

const authGuardMiddleware = (to, from, next) => {
  const isAutenticado = store.state.autenticacao.autenticado;

  if ((!isAutenticado && to.name != 'NavegacaoLogin') || to.path === '/AutenticacaoPorToken') {
    return next({ name: 'NavegacaoLogin', query: to.query });
  }

  // let storeAutenticacaoSemPermissao = false;
  // if (storeAutenticacaoSemPermissao) {
  //   return next({ name: 'SemPermissao' });
  // }

  // if (store.state.autenticacao.usuario.Id == 43) {
  //   return next({ name: 'AdminSemPermissao' });
  // }

  return next();
};

export { authGuardMiddleware };
