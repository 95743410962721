const _emptyOrNull = (array) => {
	if ((array) && (array.length > 0)) {
		return true;
	}

	return false;
};

const _groupBy = (xs, key) => {
	return xs.reduce((rv, x) => {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});
};

const _groupByObject = (array, key, childrenKey) => {
	let groupedObject = [];
	let grouped = _groupBy(array, key);

	for (var parentValue in grouped) {
		let objItem = {};
		objItem[key] = parentValue;
		objItem[childrenKey] = grouped[parentValue];
		groupedObject.push(objItem);
	}

	return groupedObject;
};

const _dynamicSort = (property) => {
	var sortOrder = 1;
	if (property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}

	return function (a, b) {
		var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
		return result * sortOrder;
	}
};

const _uniqueByKey = (array, key) => {
	return [
		...new Map(array.map(item => [item[key], item])).values()
	];
};

const arrayApi = {
	groupBy: _groupBy,
	groupByObject: _groupByObject,
	emptyOrNull: _emptyOrNull,
	dynamicSort: _dynamicSort,
	uniqueByKey: _uniqueByKey,
};

export default arrayApi;