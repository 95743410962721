import { actions } from './actions';
import { mutations, getDefaultState } from './mutations';
import { getters } from './getters';

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
