/* eslint-disable prettier/prettier */
import arrayApi from '@/core/apis/arrayApi';
import { truncateDecimals } from '@/common/number';
import { ID_UNIDADE } from '@/core/enums/unidade-compra-venda.enum';

export const getDefaultDevolucaoState = () => {
  let defaultSate = {
    Identificacao: {
      Tipo: null,
      Fornecedor: null,
    },
    Associacao: {
      Documento: null,
      Serie: null,
      ChaveDeAcesso: null,
    },
    Conferencia: {
      Referenciados: [],
      NaturezaOperacao: null,
      Deposito: null,
      DadosAdicionais: {
        TipoFrete: '',
        Transportador: '',
        ObservacaoPadrao: '',
      },
      DadosAdicionaisPorNota: [],
    },
    Produtos: {},
    ItensSemVinculo: [], //Validar pq é usado na pesquisa por nota esse campo
    ProdutosAssociacaoManual: [],
    NotasAssociadas: [],
    NotasAssociadasComItensSelecionados: [],
    Finalizacao: {
      Id: null,
      Documento: null,
      Serie: '',
      ValorTotal: 0,
    },
    Autorizacao: {
      Chave: '',
      CodigoRetorno: null,
      IdNFe: null,
      NomeArquivoDANFE: '',
    },
    produtos: [],
    listaProdutos: [],
    produtosSemNota: [],
    tipoArquivoReferenciaEntrada: null,
  };

  return defaultSate;
};

export const getDefaultState = () => {
  let defaultSate = {
    ...getDefaultDevolucaoState(),
    Configuracoes: {
      preferenciasEmpresa: {
        CodigoNaturezaOperacao: null,
        Serie: null,
        IdDeposito: null,
        MostraIcmsStObs: false,
        EmitirNotasPorChaveOrigem: false,
      },
      preferenciasEmpresaValidas: false,
      listasGerais: {
        NaturezasOperacao: [],
        Series: [],
        Depositos: [],
        produtoQuantidadeDivergente: [],
        CentrosCusto: [],
      },
      PesquisaProdutoAceitaCodigoAlfanumerico: false,
      PesquisaProdutoPorSerial: true,
      ControlaLoteDatas: false,
      ControlaLote: false,
    },
    urlBaseERP: null,
    iniciado: false,
  };

  return defaultSate;
};

export const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState());
  },

  atualizarFornecedor(state, payload) {
    state.Identificacao.Fornecedor = payload;
  },

  atualizarTipoDevolucao(state, payload) {
    state.Identificacao.Tipo = payload;
  },

  atualizarAssociacao(state, payload) {
    state.Associacao.Documento = payload.Documento;
    state.Associacao.Serie = payload.Serie;
    state.Associacao.ChaveDeAcesso = payload.ChaveDeAcesso;
  },

  resetDevolucao(state) {
    Object.assign(state, getDefaultDevolucaoState());
  },

  atualizarPreferenciasEmpresa(state, payload) {
    if (!payload) {
      return;
    }

    let listas = state.Configuracoes.listasGerais;

    let preferenciasParaSalvar = {
      Serie: null,
      CodigoNaturezaOperacao: null,
      IdDeposito: null,
      MostraIcmsStObs: payload.MostraIcmsStObs === false ? false : true,
      EmitirNotasPorChaveOrigem: payload.EmitirNotasPorChaveOrigem === false ? false : true,
      IdCentroCustoDevolucao: payload.IdCentroCustoDevolucao,
    };

    if (
      listas.Series.some((serie) => {
        return serie.Serie == payload.Serie;
      })
    ) {
      preferenciasParaSalvar.Serie = payload.Serie;
    }

    if (
      listas.NaturezasOperacao.some((natureza) => {
        return natureza.Codigo == payload.CodigoNaturezaOperacao;
      })
    ) {
      preferenciasParaSalvar.CodigoNaturezaOperacao = payload.CodigoNaturezaOperacao;
    }

    if (
      listas.Depositos.some((deposito) => {
        return deposito.Id == payload.IdDeposito;
      })
    ) {
      preferenciasParaSalvar.IdDeposito = payload.IdDeposito;
    }
    state.Configuracoes.preferenciasEmpresa = preferenciasParaSalvar;
  },

  salvarValidacaoPreferenciasEmpresa(state, valido) {
    state.Configuracoes.preferenciasEmpresaValidas = valido;
  },

  atualizarStatusDadosIniciais(state, payload) {
    state.obteveDadosIniciais = payload;
  },

  atualizarListas(state, payload) {
    state.iniciado = true;
    state.Configuracoes.listasGerais.NaturezasOperacao = payload.NaturezasOperacao;
    state.Configuracoes.listasGerais.Depositos = payload.Depositos;
    state.Configuracoes.listasGerais.Series = payload.Series;
    state.Configuracoes.listasGerais.Cests = payload.Cests;
    state.Configuracoes.listasGerais.Cfops = payload.Cfops;
    state.Configuracoes.listasGerais.Csosns = payload.Csosns;
    state.Configuracoes.listasGerais.CstsIcms = payload.CstsIcms;
    state.Configuracoes.listasGerais.CstsIpi = payload.CstsIpi;
    state.Configuracoes.listasGerais.CstsPisCofins = payload.CstsPisCofins;
    state.Configuracoes.listasGerais.Ncms = payload.Ncms;
    state.Configuracoes.listasGerais.TiposFrete = payload.TiposFrete;
    state.Configuracoes.listasGerais.Transportadores = payload.Transportadores;
    state.Configuracoes.listasGerais.ObservacoesPadrao = payload.ObservacoesPadrao;
    state.Configuracoes.listasGerais.CentrosCusto = payload.CentrosCusto;
  },

  atualizarConfiguracaoEmpresa(state, payload) {
    state.urlBaseERP = payload.EnderecoServidor + '/';
  },

  atualizarParametrosEmpresa(state, payload) {
    state.Configuracoes.PesquisaProdutoAceitaCodigoAlfanumerico = payload;
  },

  atualizarParametrosLote(state, payload) {
    state.Configuracoes.ControlaLoteDatas = payload.ControlaLoteDatas;
    state.Configuracoes.ControlaLote = payload.ControleLote;
  },

  atualizarNotasConferencia(state, payload) {
    payload.forEach((notaConferencia) => {
      var notaAssociada = state.NotasAssociadas.find((n) => n.Id == notaConferencia.Id);
      if (notaAssociada)
        notaConferencia.Itens.forEach((itemConferencia) => {
          var itemAssociado = notaAssociada.Itens.find((item) => item.Transacao == itemConferencia.Transacao);
          if (itemAssociado) itemConferencia.Serial = itemAssociado.Serial;
        });
    });
    state.Conferencia.Referenciados = payload;
  },

  conferido(state, payload) {
    state.Conferencia.Deposito = payload.Deposito;
    state.Conferencia.NaturezaOperacao = payload.NaturezaOperacao;
    state.Conferencia.DadosAdicionais = payload.DadosAdicionais;
  },

  inserirItemSemVinculo(state, payload) {
    state.ItensSemVinculo.push(payload);
  },

  inserirProduto(state, payload) {
    state.Produtos[payload.CodigoProduto] = payload;
  },

  finalizado(state, payload) {
    Object.assign(state.Finalizacao, payload);
  },

  autorizado(state, payload) {
    Object.assign(state.Autorizacao, payload);
  },
  atualizarItemSemVinculo(state, payload) {
    state.ItensSemVinculo.splice(payload.indiceProdutoExistente, 1, payload.itemExistente);
  },

  atualizarMultiplosItensSemVinculo(state, payload) {
    state.ItensSemVinculo = payload.concat(state.ItensSemVinculo);
    state.ItensSemVinculo.forEach((item) => {
      item.Selecionado = false;
    });
  },

  atualizarTodosItensSemVinculo(state, payload) {
    state.ItensSemVinculo = payload;
  },

  removerItemSemVinculo(state, payload) {
    state.ItensSemVinculo.splice(payload, 1);
  },

  removerTodosItensSemVinculo(state) {
    state.ItensSemVinculo = [];
  },

  inserirNotaAssociada(state, payload) {
    payload.Itens.forEach((item) => {
      if (item.UnidadeCompra) 
        item.UnidadeCompra = {
          Id: ID_UNIDADE.Compra,
          Descricao: item.UnidadeCompra,
          Quantidade: truncateDecimals(item.Quantidade / item.FatorConversao, item.UnidadeCompraCasasDecimais || 0),
          QuantidadeLimite: truncateDecimals(item.QuantidadeLimite / item.FatorConversao, item.UnidadeCompraCasasDecimais || 0),
          PrecoUnitario: item.PrecoUnitario * item.FatorConversao,
          CasasDecimais: item.UnidadeCompraCasasDecimais,
        };
      
      item.UnidadeVenda = {
        Id: ID_UNIDADE.Venda,
        Descricao: item.UnidadeVenda,
        Quantidade: truncateDecimals(item.Quantidade, item.UnidadeVendaCasasDecimais || 0),
        QuantidadeLimite: truncateDecimals(item.QuantidadeLimite, item.UnidadeVendaCasasDecimais || 0),
        PrecoUnitario: item.PrecoUnitario,
        CasasDecimais: item.UnidadeVendaCasasDecimais,
      };

      item.UnidadeSelecionada = item.UnidadeVenda;
    });

    state.NotasAssociadas.push(payload);
  },

  selecionarTodosItensAssociados(state, payload) {
    state.NotasAssociadas[payload.index].Itens = payload.itens;
  },

  removerTodasNotasAssociadas(state) {
    state.NotasAssociadas = [];
  },

  removerNotaAssociada(state, payload) {
    state.NotasAssociadas.splice(payload, 1);
  },

  removerItemNotaAssociada(state, payload) {
    state.NotasAssociadas.filter((nota) => {
      if (nota.Id == payload.idNotaAssociada) {
        nota.Itens.splice(payload.indiceRemocao, 1);
        return;
      }
    });
  },

  atualizarItemNotaAssociada(state, payload) {
    state.NotasAssociadas[payload.indiceNota].Itens.splice(payload.indiceItemExistente, 1, payload.itemExistente);
  },

  alterarUnidadeItemNotaAssociada(state, { indiceNota, indiceItem, unidade }) {
    const item = state.NotasAssociadas[indiceNota].Itens[indiceItem];
    
    if (item) {
      item.UnidadeSelecionada = unidade;
      item.Quantidade = unidade.Quantidade;
      item.QuantidadeLimite = unidade.QuantidadeLimite;
      item.PrecoUnitario = unidade.PrecoUnitario;
    }
  },

  adicionarItemNotaAssociada(state, payload) {
    payload.nota.Itens.push(payload.produto);
  },

  atualizarNotasAssociadasComItemsSelecionados(state, payload) {
    state.NotasAssociadasComItensSelecionados = payload;
  },

  removerTodasNotasAssociadasComItemSelecionado(state, payload) {
    state.NotasAssociadasComItensSelecionados = [];
  },

  inserirProdutoAssociacaoManual(state, payload) {
    var produto = state.ProdutosAssociacaoManual.find((p) => p.CodigoProduto == payload.CodigoProduto);
    if (produto) {
      var entradas = arrayApi.uniqueByKey(produto.EntradasDisponiveis.concat(payload.EntradasDisponiveis), 'Transacao');
      var quantidade = produto.Quantidade + payload.Quantidade;
      this._vm.$set(produto, 'EntradasDisponiveis', entradas);
      this._vm.$set(produto, 'Quantidade', quantidade);
    } else state.ProdutosAssociacaoManual.push(payload);
  },

  alterarUnidadeNotaProdutoAssociacaoManual(state, { indiceProduto, indiceNota, unidade }) {
    var notaProduto = state.ProdutosAssociacaoManual[indiceProduto]?.EntradasDisponiveis[indiceNota];

    if (notaProduto) {
      notaProduto.UnidadeSelecionada = unidade;
      notaProduto.QuantidadePendente = unidade.QuantidadePendente;
      notaProduto.PrecoUnitario = unidade.PrecoUnitario;
        
      const quantidadeSelecionada = unidade.Id == ID_UNIDADE.Venda ? 
        notaProduto.QuantidadeSelecionada * notaProduto.FatorConversao : 
        truncateDecimals(notaProduto.QuantidadeSelecionada / notaProduto.FatorConversao, unidade.CasasDecimais);

      notaProduto.AlterarQuantidade(quantidadeSelecionada);
    }
  },

  removerProdutoAssociacaoManual(state, payload) {
    state.ProdutosAssociacaoManual = state.ProdutosAssociacaoManual.filter((p) => p.CodigoProduto !== payload.CodigoProduto);
    if (state.listaProdutos.length > 0)
      state.listaProdutos = state.listaProdutos.filter((p) => p.CodigoProduto !== payload.CodigoProduto);
  },

  removerTodosProdutosAssociacaoManual(state) {
    state.ProdutosAssociacaoManual = [];
  },
  filtrarProdutos(state, payload) {
    if (state.listaProdutos.length == 0) state.listaProdutos = state.ProdutosAssociacaoManual;

    state.produtosComNota = state.ProdutosAssociacaoManual.filter((p) => p.EntradasDisponiveis.length != 0);
    state.produtosSemNota = state.ProdutosAssociacaoManual.filter((p) => p.EntradasDisponiveis.length == 0);
    let filtrarProdQtdeDivergentes = payload.every((q) => q == 1);
    let filtrarProdSemNota = payload.every((n) => n == 2);
    let filtrarProdSemNotaEQte = false;
    if (payload.length == 2) {
      filtrarProdSemNotaEQte = true;
    }
    if (filtrarProdQtdeDivergentes) {
      let prodQtdeDivergente = state.produtosComNota.filter((p) => {
        let qtdeProdNotas = 0;
        p.EntradasDisponiveis.forEach((q) => (qtdeProdNotas += q.QuantidadePendente));
        return p.Quantidade > qtdeProdNotas;
      });

      state.ProdutosAssociacaoManual = prodQtdeDivergente;
    }
    if (filtrarProdSemNota) {
      state.ProdutosAssociacaoManual = state.produtosSemNota;
    }
    if (filtrarProdSemNotaEQte) {
      state.produtosComNota = state.listaProdutos.filter((p) => p.EntradasDisponiveis.length != 0);
      state.produtosSemNota = state.listaProdutos.filter((p) => p.EntradasDisponiveis.length == 0);

      let prodSemNota = state.produtosSemNota;
      let prodQtdeDivergente = state.produtosComNota.filter((p) => {
        let qtdeProdNotas = 0;
        p.EntradasDisponiveis.forEach((q) => (qtdeProdNotas += q.QuantidadePendente));
        return p.Quantidade > qtdeProdNotas;
      });

      let listaFiltrada = prodSemNota.concat(prodQtdeDivergente);

      state.ProdutosAssociacaoManual = listaFiltrada;
    }
  },

  listaProdutos(state) {
    return (state.ProdutosAssociacaoManual = state.listaProdutos);
  },

  atualizarDadosAdicionaisNotasConferencia(state, payload) {
    let idxDadosAdicionaisPorNota = state.Conferencia.DadosAdicionaisPorNota.findIndex(
      (t) => t.NumeroNota == payload.NumeroNota && t.SerieNota == payload.SerieNota,
    );

    if (idxDadosAdicionaisPorNota >= 0) {
      let _dadosAdicionais = state.Conferencia.DadosAdicionaisPorNota[idxDadosAdicionaisPorNota];
      state.Conferencia.DadosAdicionaisPorNota.splice(idxDadosAdicionaisPorNota, 1, { ..._dadosAdicionais, ...payload });
    } else {
      state.Conferencia.DadosAdicionaisPorNota.push(payload);
    }
  },
  atualizarTipoArquivoReferenciaEntrada(state, payload) {
    state.tipoArquivoReferenciaEntrada = payload;
  },
};
