import * as signalR from 'signalr';
import apiClient from '@/services/apiClient';
import apiConfig from '@/services/apiConfig';
import swal from '@/common/alerts';
import { baixarArquivoExcel } from '@/common/files';

const baseUrl = `http://localhost:57385`;

const mocks = {
  obterDadosIniciais: {
    Importacoes: [
      {
        IdIntegracaoImagensVTEX: 1,
        IdSituacao: 1,
        Descricao: 'Importação Mock 01 - PROCESSANDO',
        DataCriacao: '2021-11-14T15:37:00',
        QuantidadeDeRegistrosImportados: 5,
        QuantidadeDeRegistrosComErros: 2,
      },
      {
        IdIntegracaoImagensVTEX: 2,
        IdSituacao: 2,
        Descricao: 'Importação Mock 02 - CONCLUÍDO',
        DataCriacao: '2022-01-14T15:37:00',
        QuantidadeDeRegistrosImportados: 5,
        QuantidadeDeRegistrosComErros: 0,
      },
      {
        IdIntegracaoImagensVTEX: 3,
        IdSituacao: 3,
        Descricao: 'Importação Mock 03 - CONCLUÍDO COM INCONSISTÊNCIAS',
        DataCriacao: '2022-02-14T15:37:00',
        QuantidadeDeRegistrosImportados: 10,
        QuantidadeDeRegistrosComErros: 1,
      },
      {
        IdIntegracaoImagensVTEX: 4,
        IdSituacao: 4,
        Descricao: 'Importação Mock 04 - CANCELADO',
        DataCriacao: '2022-03-14T15:37:00',
        QuantidadeDeRegistrosImportados: 40,
        QuantidadeDeRegistrosComErros: 10,
      },
      {
        IdIntegracaoImagensVTEX: 5,
        IdSituacao: 1,
        Descricao: 'Importação Mock 05 - TESTE FILTRO',
        DataCriacao: '2022-04-14T15:37:00',
        QuantidadeDeRegistrosImportados: 5,
        QuantidadeDeRegistrosComErros: 2,
      },
    ],
    TiposValidacao: [
      {
        Id: 1,
        Descricao: 'Código do Produto',
      },
      {
        Id: 2,
        Descricao: 'Descrição',
      },
      {
        Id: 3,
        Descricao: 'Referência',
      },
      {
        Id: 4,
        Descricao: 'Código Auxiliar',
      },
    ],
    DadosDeConfiguracaoVTEX: {
      NomeDaConta: '',
      NomeDoAmbiente: '',
      ChaveVTEX: '',
      TokenVTEX: '',
    },
  },
  obterErros: {
    IdImportacao: 3,
    DescricaoImportacao: 'Importação Mock 03 - CONCLUÍDO COM INCONSISTÊNCIAS',
    ErrosVTEX: [
      {
        CodigoProduto: '12345',
        CodigoProdutoVTEX: '54321',
        CodigoSkuVTEX: '52123',
        Erros: [
          'O produto 54321 ultrapassou o limite de 5 imagens secundárias.<br><br>Imagens Importadas:<br>https//www.imagemimportada.com.br<br><br>Imagens não importadas:<br>https//www.imagemnaoimportada.com.br',
        ],
      },
    ],
  },
  obterArquivoDeErros: {
    Content: '',
    FileName: 'Inconsistencias_ImportacaoImagensVTEX.xlsx',
  },
  executarImportacao: 1,
  salvarDadosDeConfiguracao: 1,
};

const obterDadosMock = (chave) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const mock = mocks[chave];
      resolve(mock);
    }, 1000);
  });
};

const obterEstadoDeLoading = () => {
  const state = {};

  state.nomesLoadingsPorAction = {
    salvarDadosDeConfiguracao: 'salvarDadosDeConfiguracao imagens',
    obterDadosIniciais: 'obterDadosIniciais imagens',
    obterErros: 'obterErros imagens',
    obterArquivoDeErros: 'obterArquivoDeErros imagens',
    filtrarImportacoes: 'filtrarImportacoes imagens',
    executarImportacao: 'executarImportacao imagens',
    executarConexaoHUB: 'executarConexaoHUB imagens',
    reniciarConexaoComHUB: 'reniciarConexaoComHUB imagens',
    cancelarImportacao: 'cancelarImportacao imagens',
    executarReImportacao: 'executarReImportacao imagens',
  };

  state.loadingsPorComponente = {
    imagemModalConfiguracoes: [state.nomesLoadingsPorAction.salvarDadosDeConfiguracao],
    imagemImportacaoTable: [
      state.nomesLoadingsPorAction.obterDadosIniciais,
      state.nomesLoadingsPorAction.filtrarImportacoes,
      state.nomesLoadingsPorAction.executarConexaoHUB,
      state.nomesLoadingsPorAction.reniciarConexaoComHUB,
      state.nomesLoadingsPorAction.cancelarImportacao,
      state.nomesLoadingsPorAction.executarReImportacao,
    ],
    imagemImportacaoModalVisualizarErros: [
      state.nomesLoadingsPorAction.obterErros,
      state.nomesLoadingsPorAction.obterArquivoDeErros,
    ],
    imagemImportacaoModalFormulario: [state.nomesLoadingsPorAction.executarImportacao],
  };

  return { ...state };
};

const obterEnumStatusImportacaoState = () => {
  return {
    processando: 1,
    concluido: 2,
    concluidoComInconsistencias: 3,
    cancelado: 4,
  };
};

const obterEstadoPadrao = () => ({
  loading: obterEstadoDeLoading(),
  enumDeStatusImportacao: obterEnumStatusImportacaoState(),
  tiposValidacao: [],
  tipoValidacaoSelecionado: null,
  modalDeConfiguracoesAberto: false,
  modalVisualizarErrosAberto: false,
  modalFormularioNovaImportacaoAberto: false,
  importacoes: [],
  importacoesFiltradas: [],
  importacaoSelecionada: null,
  errosImportacaoSelecionada: null,
  filtroParaImportacoes: '',
  dadosDeConfiguracaoVTEX: null,
  conexaoHUB: null,
});

const mutations = {
  abrirModalConfiguracoes(state) {
    state.modalDeConfiguracoesAberto = true;
  },
  fecharModalConfiguracoes(state) {
    state.modalDeConfiguracoesAberto = false;
  },
  abrirModalVisualizarErros(state) {
    state.modalVisualizarErrosAberto = true;
  },
  fecharModalVisualizarErros(state) {
    state.errosImportacaoSelecionada = null;
    state.modalVisualizarErrosAberto = false;
  },
  abrirModalFormularioNovaImportacao(state) {
    state.modalFormularioNovaImportacaoAberto = true;
  },
  fecharModalFormularioNovaImportacao(state) {
    state.tipoValidacaoSelecionado = null;
    state.modalFormularioNovaImportacaoAberto = false;
  },
  atualizarDadosIniciais(state, payload) {
    state.importacoes = payload.importacoes;
    state.filtroParaImportacoes = '';
    state.tiposValidacao = payload.tiposValidacao;
    state.dadosDeConfiguracaoVTEX = payload.dadosDeConfiguracaoVTEX;
  },
  atualizarImportacaoSelecionada(state, payload) {
    state.importacaoSelecionada = payload;
  },
  atualizarStatusImportacaoSelecionada(state, payload) {
    state.importacaoSelecionada.idStatus = payload;
  },
  atualizarErrosImportacaoSelecionada(state, payload) {
    state.errosImportacaoSelecionada = payload;
  },
  atualizarImportacoesFiltradas(state, payload) {
    if (payload && payload.length != 0) {
      state.importacoesFiltradas = payload.sort((a, b) => {
        return b.id - a.id;
      });
    } else state.importacoesFiltradas = payload;
  },
  atualizarFiltroParaImportacoes(state, payload) {
    state.filtroParaImportacoes = payload;
  },
  atualizarTipoValidacaoSelecionado(state, payload) {
    state.tipoValidacaoSelecionado = payload;
  },
  atualizarDadosdeConfiguracao(state, payload) {
    state.dadosDeConfiguracaoVTEX = payload;
  },
  atualizarStatusImportacao(state, value) {
    let importacaoParaAtualizar = state.importacoes.find((importacao) => importacao.id == value.IdImportacaoImagensVtex);
    if (importacaoParaAtualizar) {
      importacaoParaAtualizar.quantidadeDeRegistrosImportados = value.ProdutosImportados;
      importacaoParaAtualizar.quantidadeDeRegistrosComErros = value.ProdutosComDivergencias;
      importacaoParaAtualizar.quantidadeTotalDeRegistros = value.ProdutosProcessados;
      importacaoParaAtualizar.idStatus = value.IdSituacao;
    }
  },
  setarConfiguracaoInicialHUB(state, payload) {
    state.conexaoHUB = global.window.$.hubConnection();
    // const url = `${baseUrl}/signalr`;
    const url = `${apiConfig.getUrlSuprimentosApi()}/signalr`;
    state.conexaoHUB.url = url;
    state.conexaoHUB.qs = {
      Authorization: payload.tokenJWT,
    };
  },
  pararConexaoComHUB(state) {
    if (state.hubConnection) state.hubConnection.stop();
  },
};

const actions = {
  async salvarDadosDeConfiguracao({ commit, getters }, payload) {
    try {
      const url = `${apiConfig.getUrlSuprimentosApi()}/api/IntegracaoImagensVtex/SalvarDadosDeConfiguracao`;
      // const url = `${baseUrl}/api/IntegracaoImagensVtex/SalvarDadosDeConfiguracao`;
      const { data } = await apiClient.post(url, JSON.stringify(payload));
      const dadosDeConfiguracaoMapeados = getters.obterDadosDeConfiguracaoMapeados(payload);

      commit('atualizarDadosdeConfiguracao', dadosDeConfiguracaoMapeados);
      commit('fecharModalConfiguracoes');

      return data;
    } catch (err) {
      throw new Error(err);
    }
  },
  async obterDadosIniciais({ commit, getters }) {
    try {
      const url = `${apiConfig.getUrlSuprimentosApi()}/api/IntegracaoImagensVtex/ObterDadosIniciais`;
      // const url = `${baseUrl}/api/IntegracaoImagensVtex/ObterDadosIniciais`;
      const { data } = await apiClient.get(url);
      // const data = await obterDadosMock('obterDadosIniciais');
      const dadosIniciaisMapeados = getters.obterDadosIniciaisMapeados(data);

      commit('atualizarDadosIniciais', dadosIniciaisMapeados);
      commit('atualizarImportacoesFiltradas', dadosIniciaisMapeados.importacoes);

      return data;
    } catch (err) {
      throw new Error(err);
    }
  },
  async obterErros({ state, commit, getters }) {
    try {
      const importacaoSelecionada = state.importacaoSelecionada.id;
      const url = `${apiConfig.getUrlSuprimentosApi()}/api/IntegracaoImagensVtex/ObterErros?IdImportacaoImagensVTEX=${importacaoSelecionada}`;
      // const url = `${baseUrl}/api/IntegracaoImagensVtex/ObterErros?IdImportacaoImagensVTEX=${state.importacaoSelecionada.id}`;

      const { data } = await apiClient.get(url);
      // const data = await obterDadosMock('obterErros');
      const errosMapeados = getters.obterImportacaoErrosMapeados(data);

      commit('atualizarErrosImportacaoSelecionada', errosMapeados);

      return data;
    } catch (err) {
      throw new Error(err);
    }
  },
  async obterArquivoDeErros({ state }) {
    try {
      const idErroImportacaoSelecionada = state.errosImportacaoSelecionada.idImportacao;
      const url = `${apiConfig.getUrlSuprimentosApi()}/api/IntegracaoImagensVtex/ObterArquivoDeErros?IdImportacaoImagensVTEX=${idErroImportacaoSelecionada}`;
      // const url = `${baseUrl}/api/IntegracaoImagensVtex/ObterArquivoDeErros?IdImportacaoImagensVTEX=${idErroImportacaoSelecionada}`;
      const { data } = await apiClient.get(url);

      baixarArquivoExcel(data);

      return data;
    } catch (err) {
      throw new Error(err);
    }
  },
  async executarImportacao({ state, dispatch, commit }) {
    try {
      var idTipoValidacao = state.tipoValidacaoSelecionado.id;
      const url = `${apiConfig.getUrlSuprimentosApi()}/api/IntegracaoImagensVtex/IniciarNovaIntegracao?IdTipoValidacao=${idTipoValidacao}`;
      // const url = `${baseUrl}/api/IntegracaoImagensVtex/IniciarNovaIntegracao?IdTipoValidacao=${state.tipoValidacaoSelecionado.id}`;
      const { data } = await apiClient.get(url);

      commit('fecharModalFormularioNovaImportacao');
      await dispatch('obterDadosIniciais');

      return data;
    } catch (err) {
      throw new Error(err);
    }
  },
  async filtrarImportacoes({ state, commit, getters }) {
    function filtrarImportacoesAsync(state, commit) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const filtroParaImportacoesFormatado = state.filtroParaImportacoes.toLowerCase().trim();

          if (!filtroParaImportacoesFormatado) {
            commit('atualizarImportacoesFiltradas', state.importacoes);
            return resolve(state.importacoes);
          }

          const listaDeImportacoes = [...state.importacoes];

          const listaDeImportacoesFiltradas = listaDeImportacoes.filter((importacao) => {
            const importacaoFiltrada = Object.keys(importacao)
              .map((prop) => {
                if (prop == 'descricao') return importacao[prop];
                if (prop == 'dataCriacao') return importacao[prop];
                if (prop == 'idStatus') return getters.obterDescricaoStatusImportacao(importacao[prop]);
              })
              .toString()
              .toLowerCase()
              .includes(state.filtroParaImportacoes.toLowerCase().trim());

            if (importacaoFiltrada) return importacao;
          });

          commit('atualizarImportacoesFiltradas', listaDeImportacoesFiltradas);
          return resolve();
        }, 1000);
      });
    }

    await filtrarImportacoesAsync(state, commit);
  },
  async executarConexaoHUB({ state, commit, rootState }) {
    commit('setarConfiguracaoInicialHUB', { tokenJWT: rootState.autenticacao.tokenJWT });

    let integracaoImagensVtexHub = state.conexaoHUB.createHubProxy('integracaoImagensVtexHub');
    integracaoImagensVtexHub.on('atualizarStatusImportacaoImagensVtex', (payload) =>
      commit('atualizarStatusImportacao', payload),
    );

    state.conexaoHUB
      .start()
      .done(() => {
        let groupName = rootState.autenticacao.empresa.Portal;
        integracaoImagensVtexHub
          .invoke('joinGroup', groupName)
          .done(() => {})
          .fail((error) => {
            console.log('Error: ' + error);
          });
      })
      .fail((err) => {
        console.log(err);
        swal.exibirMensagemErro('Não foi possível abrir uma conexão com o Hub!');
      });
  },
  async reniciarConexaoComHUB({ dispatch, commit }) {
    commit('pararConexaoComHUB');
    await dispatch('executarConexaoHUB');
    await dispatch('obterDadosIniciais');
  },
  async cancelarImportacao({ state, dispatch }) {
    try {
      const url = `${apiConfig.getUrlSuprimentosApi()}/api/IntegracaoImagensVtex/CancelarImportacaoImagens`;
      // const url = `${baseUrl}/api/IntegracaoImagensVtex/CancelarImportacaoImagens`;
      const { data } = await apiClient.post(
        url,
        JSON.stringify({
          Identificador: state.importacaoSelecionada.identificadorCancelamento,
        }),
      );

      await dispatch('obterDadosIniciais');
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },
  async executarReImportacao({ state, dispatch }) {
    try {
      const url = `${apiConfig.getUrlSuprimentosApi()}/api/IntegracaoImagensVtex/IniciarReimportacao`;
      // const url = `${baseUrl}/api/IntegracaoImagensVtex/IniciarReimportacao`;
      var command = JSON.stringify({ IdIntegracao: state.importacaoSelecionada.id });
      const { data } = await apiClient.post(url, command);
      await dispatch('obterDadosIniciais');
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },
};

const getters = {
  importacaoImagemModalConfiguracoesCarregando(state, getters, rootState, rootGetters) {
    const { imagemModalConfiguracoes } = state.loading.loadingsPorComponente;
    return rootGetters['manageLoadingStates/is'](imagemModalConfiguracoes);
  },

  importacaoImagemModalVisualizarErrosCarregando(state, getters, rootState, rootGetters) {
    const { imagemImportacaoModalVisualizarErros } = state.loading.loadingsPorComponente;
    return rootGetters['manageLoadingStates/is'](imagemImportacaoModalVisualizarErros);
  },

  importacaoImagemModalFormularioCarregando(state, getters, rootState, rootGetters) {
    const { imagemImportacaoModalFormulario } = state.loading.loadingsPorComponente;
    return rootGetters['manageLoadingStates/is'](imagemImportacaoModalFormulario);
  },

  importacaoImagemTableCarregando(state, getters, rootState, rootGetters) {
    const { imagemImportacaoTable } = state.loading.loadingsPorComponente;
    return rootGetters['manageLoadingStates/is'](imagemImportacaoTable);
  },

  obterDescricaoDoCarregamento(state, getters, rootState, rootGetters) {
    const {
      salvarDadosDeConfiguracao,
      obterDadosIniciais,
      obterErros,
      obterArquivoDeErros,
      filtrarImportacoes,
      executarImportacao,
      reniciarConexaoComHUB,
      executarConexaoHUB,
      cancelarImportacao,
    } = state.loading.nomesLoadingsPorAction;

    const salvandoDadosDeConfiguracao = rootGetters['manageLoadingStates/is'](salvarDadosDeConfiguracao);
    const obtendoDadosIniciais = rootGetters['manageLoadingStates/is'](obterDadosIniciais);
    const obtendoErrosImportacaoSelecionada = rootGetters['manageLoadingStates/is'](obterErros);
    const obtendoArquivoDeErrosImportacaoSelecionada = rootGetters['manageLoadingStates/is'](obterArquivoDeErros);
    const filtrandoImportacoes = rootGetters['manageLoadingStates/is'](filtrarImportacoes);
    const iniciandoNovaImportacao = rootGetters['manageLoadingStates/is'](executarImportacao);
    const reniciandoConexaoHUB = rootGetters['manageLoadingStates/is'](reniciarConexaoComHUB);
    const executandoConexaoHUB = rootGetters['manageLoadingStates/is'](executarConexaoHUB);
    const cancelandoImportacao = rootGetters['manageLoadingStates/is'](cancelarImportacao);

    if (salvandoDadosDeConfiguracao) return 'Salvando configurações...';
    if (obtendoDadosIniciais) return 'Buscando importações...';
    if (obtendoErrosImportacaoSelecionada) return 'Buscando erros...';
    if (obtendoArquivoDeErrosImportacaoSelecionada) return 'Buscando arquivo de erros...';
    if (filtrandoImportacoes) return 'Filtrando importações...';
    if (iniciandoNovaImportacao) return 'Iniciando importação de imagens...';
    if (reniciandoConexaoHUB) return 'Atualizando lista de importações...';
    if (executandoConexaoHUB) return 'Iniciando conexão em tempo real...';
    if (cancelandoImportacao) return 'Cancelando importação...';

    return 'Aguarde...';
  },

  obterDescricaoStatusImportacao: (state) => (idStatus) => {
    switch (idStatus) {
      case state.enumDeStatusImportacao.processando:
        return 'Processando';
      case state.enumDeStatusImportacao.concluido:
        return 'Concluído';
      case state.enumDeStatusImportacao.concluidoComInconsistencias:
        return 'Concluído com inconsistências';
      case state.enumDeStatusImportacao.cancelado:
        return 'Cancelado';
    }
  },

  obterDadosIniciaisMapeados: (state, getters) => (dadosIniciais) => {
    const importacoesMapeadas = dadosIniciais.Importacoes.map((importacao) => {
      var data = new Date(importacao.DataCriacao);
      return {
        id: importacao.IdIntegracaoImagensVTEX,
        idStatus: importacao.IdSituacao,
        descricao: importacao.Nome,
        dataCriacao: data.toLocaleString('pt-BR'),
        quantidadeDeRegistrosImportados: importacao.QuantidadeDeRegistrosImportados,
        quantidadeDeRegistrosComErros: importacao.QuantidadeDeRegistrosComErros,
        quantidadeTotalDeRegistros: importacao.QuantidadeDeRegistrosImportados + importacao.QuantidadeDeRegistrosComErros,
        identificadorCancelamento: importacao.IdentificadorCancelamento,
      };
    });

    const tiposValidacaoMapeados = dadosIniciais.TiposValidacao.map((tipoValidacao) => {
      return {
        id: tipoValidacao.Id,
        descricao: tipoValidacao.Descricao,
      };
    });

    const dadosDeConfiguracaoVTEXMapeados = getters.obterDadosDeConfiguracaoMapeados(dadosIniciais.DadosDeConfiguracaoVTEX);

    const dadosIniciaisMapeados = {
      importacoes: importacoesMapeadas,
      tiposValidacao: tiposValidacaoMapeados,
      dadosDeConfiguracaoVTEX: dadosDeConfiguracaoVTEXMapeados,
    };

    return dadosIniciaisMapeados;
  },

  obterImportacaoErrosMapeados: (state) => (objetoDeErros) => {
    console.log(objetoDeErros);
    const erros = objetoDeErros.ErrosVTEX.map((erro) => {
      return {
        codigoProduto: erro.CodigoProduto,
        codigoProdutoVtex: erro.CodigoProdutoVTEX,
        codigoSkuVtex: erro.CodigoSkuVTEX,
        mensagens: erro.Erros,
      };
    });

    const importacaoErrosMapeados = {
      idImportacao: state.importacaoSelecionada.id,
      descricaoImportacao: state.importacaoSelecionada.descricao,
      erros,
    };

    return importacaoErrosMapeados;
  },

  obterDadosDeConfiguracaoMapeados: () => (dadosDeConfiguracaoVTEX) => {
    const dadosDeConfiguracaoMapeados = {
      chave: dadosDeConfiguracaoVTEX.ChaveVTEX,
      nomeDaConta: dadosDeConfiguracaoVTEX.NomeDaConta,
      nomeDoAmbiente: dadosDeConfiguracaoVTEX.NomeDoAmbiente,
      token: dadosDeConfiguracaoVTEX.TokenVTEX,
    };

    return dadosDeConfiguracaoMapeados;
  },

  obterDadosDeConfiguracao(state) {
    return state.dadosDeConfiguracaoVTEX;
  },

  importacoesPossuemAlgumFiltro(state) {
    return state.importacoes.length !== state.importacoesFiltradas.length;
  },

  obterQuantidadeDeRegistrosFiltrados(state) {
    return state.importacoesFiltradas.length;
  },

  importacaoProcessando: (state) => (idStatus) => {
    return idStatus === state.enumDeStatusImportacao.processando;
  },

  importacaoSelecionadaPossuiErros(state) {
    return state.importacaoSelecionada && state.importacaoSelecionada.quantidadeDeRegistrosComErros !== 0;
  },

  importacaoSelecionadaConcluidaComInconsistencias(state) {
    return (
      state.importacaoSelecionada &&
      state.importacaoSelecionada.idStatus === state.enumDeStatusImportacao.concluidoComInconsistencias
    );
  },

  importacaoSelecionadaProcessando(state) {
    return state.importacaoSelecionada && state.importacaoSelecionada.idStatus === state.enumDeStatusImportacao.processando;
  },

  importacaoSelecionadaCancelada(state) {
    return state.importacaoSelecionada && state.importacaoSelecionada.idStatus === state.enumDeStatusImportacao.cancelado;
  },

  existeAlgumaImportacaoEmProcessamento(state) {
    var indexImportacaoEmProcessamento = state.importacoes.findIndex((importacao) => {
      return importacao.idStatus === state.enumDeStatusImportacao.processando;
    });
    return indexImportacaoEmProcessamento !== -1;
  },
};

const state = obterEstadoPadrao();

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
