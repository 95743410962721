const getRoutesNavegacao = () => {
	return [
		{
			path: '/login',
			name: 'NavegacaoLogin',
			component: () => import('@/views/NavegacaoLogin.vue'),
			meta: {
				title: 'Login',
			},
		},
		{
			path: '/NavegacaoErroPermissao',
			name: 'NavegacaoErroPermissao',
			component: () => import('@/views/NavegacaoErroPermissao.vue'),
			meta: {
				title: 'Sem permissão',
			},
		},
		{
			path: '/NavegacaoErroAdminPermissao',
			name: 'NavegacaoErroAdminPermissao',
			component: () => import('@/views/NavegacaoErroAdminPermissao.vue'),
			meta: {
				title: 'Sem permissão',
			},
		},
		{
			path: '*',
			component: () => import('@/views/NavegacaoErroPagina404.vue'),
			meta: {
				title: 'Página não encontrada',
			},
		},
	];
};

const getRoutesDevolucao = () => {
	return [
		{
			path: '/devolucao',
			name: 'DevolucaoEntrada',
			component: () => import('@/components/devolucao/DevolucaoEntrada.vue'),
			children: [
				{
					path: '/devolucao/identificacao',
					name: 'DevolucaoIdentificacao',
					component: () => import('@/components/devolucao/DevolucaoIdentificacao.vue'),
				},
				{
					path: '/devolucao/itens',
					name: 'DevolucaoItens',
					component: () => import('@/components/devolucao/Devolucao.vue'),
				},
				{
					path: '/devolucao/finalizacao',
					name: 'DevolucaoFinalizacao',
					component: () => import('@/components/devolucao/DevolucaoFinalizacao.vue'),
				},
				{
					path: '/devolucao/finalizacaPorNotas',
					name: 'DevolucaoFinalizacaoPorNotas',
					component: () => import('@/components/devolucao/DevolucaoFinalizacaoPorNotas.vue'),
				},
				{
					path: '/devolucao/configuracoes',
					name: 'DevolucaoConfiguracoes',
					component: () => import('@/components/devolucao/DevolucaoConfiguracoes.vue'),
					meta: {
						title: 'Devolução Configurações',
					},
				},
				{
					path: '/devolucao/cadastroFornecedor',
					name: 'DevolucaoFornecedor',
					component: () => import('@/components/devolucao/DevolucaoFornecedor.vue'),
					meta: {
						title: 'Cadastro de Fornecedor',
					},
				},
				{
					path: '/devolucao/conferencia',
					name: 'DevolucaoConferencia',
					component: () => import('@/components/devolucao/DevolucaoConferencia.vue'),
					meta: {
						title: 'Devolução Conferência',
					},
				},
				{
					path: '/devolucao/conferenciaNotas',
					name: 'DevolucaoConferenciaNotas',
					component: () => import('@/components/devolucao/DevolucaoConferenciaPorNotas.vue'),
					meta: {
						title: 'Devolução Conferência por Notas de Origem',
					},
				},
			],
		},
	];
};

const getRoutesBalanco = () => {
	return [
		{
			path: '/balanco',
			name: 'PainelBalancos',
			component: () => import('@/components/balanco/PainelBalancos.vue'),
		},
		{
			path: '/balanco/cadastro',
			name: 'CadastroBalancos',
			component: () => import('@/components/balanco/cadastro/CadastroBalancos.vue'),
			meta: {
				idBalanco: null,
			},
		},
		{
			path: '/balanco/executarSecao',
			name: 'ExecucaoSecao',
			component: () => import('@/components/balanco/execucao/ExecucaoSecao.vue'),
			meta: {
				idBalanco: null,
			},
		},
		{
			path: '/balanco/finalizarBalanco',
			name: 'FinalizarBalanco',
			component: () => import('@/components/balanco/acompanhamento/FinalizarBalanco.vue'),
			meta: {
				idBalanco: null,
			},
		},
		{
			path: '/balanco/relatorios',
			name: 'RelatorioDeCriticas',
			component: () => import('@/components/balanco/relatorios/RelatorioDeCriticas.vue'),
			meta: {
				idBalanco: null,
			},
		},
	];
};

const getRoutesProdutosImportacao = () => {
	return [
		{
			path: '/produtos/importacao',
			name: 'ProdutosImportacao',
			component: () => import('@/views/ProdutosImportacao.vue'),
		},
	];
};

const getRoutesProdutosImagens = () => {
	return [
		{
			path: '/produtos/importacao/imagens',
			name: 'ProdutosImportacaoImagens',
			component: () => import('@/views/ProdutosImportacaoImagem.vue'),
		},
	];
};

const getRoutesRecebimentoMercadoria = () => {
	return [
		{
			path: '/recebimento/conferencia',
			name: 'ConferenciaOnlineRecebimento',
			component: () => import('@/components/recebimento/ConferenciaOnline.vue'),
			meta: {
				idRecebimento: null,
			},
		},
		{
			path: '/recebimento/conferencia-finalizada',
			name: 'ConferenciaOnlineRecebimentoFinalizada',
			component: () => import('@/components/recebimento/ConferenciaOnlineFinalizada.vue'),
			meta: {
				idRecebimento: null,
			},
		},
	];
};

const getRoutes = () => {
	const routesNavegacao = getRoutesNavegacao();
	const routesDevolucao = getRoutesDevolucao();
	const routesBalanco = getRoutesBalanco();
	const routesProdutosImportacao = getRoutesProdutosImportacao();
	const routesProdutosImagens = getRoutesProdutosImagens();
	const routesRecebimentoMercadoria = getRoutesRecebimentoMercadoria();

	const routes = [
		...routesNavegacao,
		...routesDevolucao,
		...routesBalanco,
		...routesProdutosImportacao,
		...routesProdutosImagens,
		...routesRecebimentoMercadoria,
	];

	return routes;
};

const routes = getRoutes();

export default routes;
