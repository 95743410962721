<template>
  <div
    class="d-flex w-100"
    :class="isLoadingRunningOnModalWithVariableHeight ? 'loading-on-modal-with-variable-height' : ''"
  >
    <div v-if="nameLoading === 'loading-webapp'" id="loading-estoque-loading-container-base">
      <div id="loading-estoque-loading-overlay">
        <div id="loading-estoque-loading-center-title-container-aguarde">
          <div id="loading-estoque-loading-center-title-aguarde"> Aguarde... </div>
        </div>
        <div id="loading-estoque-loading-center-spinner">
          <div id="loading-estoque-spinner">
            <div id="loading-estoque-double-bounce1"></div>
            <div id="loading-estoque-double-bounce2"></div>
          </div>
        </div>
        <div id="loading-estoque-loading-title-container">
          <div id="loading-estoque-loading-title-linxmicrovix"> Linx Microvix </div>
          <div id="loading-estoque-loading-title-estoque">
            {{ appName }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="nameLoading === 'loading-erp'" :class="isLoadingFullScreen ? 'loading-erp-full-screen' : 'loading-erp-component'">
      <i style="color: #2c004b; font-size: 72px" class="fas fa-circle-notch fa-spin fa-3x fa-fw"></i>

      <p style="color: #2c004b; font-size: 24px">{{ labelLoading }}</p>
    </div>
  </div>
</template>

<script>
  import '@/assets/css/loading-erp.css';
  import '@/assets/css/loading-webapp.css';

  export default {
    props: {
      appName: {
        type: String,
        default: process.env.VUE_APP_NOME_APLICACAO,
      },
      nameLoading: {
        type: String,
        default: 'loading-webapp',
      },
      isLoadingFullScreen: {
        type: Boolean,
        default: true,
      },
      labelLoading: {
        type: String,
        default: 'Aguarde...',
      },
      isLoadingRunningOnModalWithVariableHeight: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style scoped>
  .loading-on-modal-with-variable-height {
    position: absolute !important;
  }
</style>
