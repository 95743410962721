const STORAGEKEY_TOKEN = 'token_api';
const STORAGEKEY_TOKEN_ANONIMO = 'token_api_anonimo';
const STORAGEKEY_URL_API_ES = 'url_es_api';
const STORAGEKEY_URL_API_NFE = 'url_nfe_api';
const STORAGEKEY_URL_API_CRM = 'url_crm_api';
const STORAGEKEY_URL_API_FISCAL = 'url_fiscal_api';
const STORAGEKEY_URL_API_FATURAMENTO = 'url_faturamento_api';
const STORAGEKEY_URL_API_FINANCEIRO = 'url_financeiro_api';
const STORAGEKEY_URL_API_LIS = 'url_lis_api';
const STORAGEKEY_URL_API_SUPRIMENTOS = 'url_suprimentos_api';
const STORAGEKEY_URL_API_IMAGENS = 'url_produtos_imagens_api';
const STORAGEKEY_URL_CONNECT_HUB = 'url_connect_hub';

const urlEsApiDev = process.env.VUE_APP_URL_ESAPI;
const urlNFeApiDev = process.env.VUE_APP_URL_NFEAPI;
const urlCrmApiDev = process.env.VUE_APP_URL_CRMAPI;
const urlFiscalApiDev = process.env.VUE_APP_URL_FISCALAPI;
const urlFaturamentoApiDev = process.env.VUE_APP_URL_FATURAMENTOAPI;
const urlFinanceiroApiDev = process.env.VUE_APP_URL_FINANCEIROAPI;
const urlLisApiDev = process.env.VUE_APP_URL_LISAPI;
const urlSuprimentosApiDev = process.env.VUE_APP_URL_SUPRIMENTOSAPI;
const urlImagensProdutoApiDev = process.env.VUE_APP_URL_IMAGENSAPI;
const urlConnectHubDev = process.env.VUE_APP_URL_CONNECTHUB;

const apiConfig = {
	getToken: () => localStorage.getItem(STORAGEKEY_TOKEN) || '',
	getTokenAnonimo: () => localStorage.getItem(STORAGEKEY_TOKEN_ANONIMO) || '',
	getUrlEsApi: () => urlEsApiDev || localStorage.getItem(STORAGEKEY_URL_API_ES) || '',
	getUrlNFeApi: () => urlNFeApiDev || localStorage.getItem(STORAGEKEY_URL_API_NFE) || '',
	getUrlCrmApi: () => urlCrmApiDev || localStorage.getItem(STORAGEKEY_URL_API_CRM) || '',
	getUrlLisApi: () => urlLisApiDev || localStorage.getItem(STORAGEKEY_URL_API_LIS) || '',
	getUrlFiscalApi: () => urlFiscalApiDev || localStorage.getItem(STORAGEKEY_URL_API_FISCAL) || '',
	getUrlFaturamentoApi: () => urlFaturamentoApiDev || localStorage.getItem(STORAGEKEY_URL_API_FATURAMENTO) || '',
	getUrlFinanceiroApi: () => urlFinanceiroApiDev || localStorage.getItem(STORAGEKEY_URL_API_FINANCEIRO) || '',
	getUrlSuprimentosApi: () => urlSuprimentosApiDev || localStorage.getItem(STORAGEKEY_URL_API_SUPRIMENTOS) || '',
	getUrlImagensProdutoApi: () => urlImagensProdutoApiDev || localStorage.getItem(STORAGEKEY_URL_API_IMAGENS),
	getUrlErpAdminApi: () => process.env.VUE_APP_URL_ERPADMIN,
	getUrlConnectHubApi: () => urlConnectHubDev || localStorage.getItem(STORAGEKEY_URL_CONNECT_HUB),

	setToken: (val) => {
		if (!val) localStorage.removeItem(STORAGEKEY_TOKEN);
		else localStorage.setItem(STORAGEKEY_TOKEN, val);
	},

	setTokenAnonimo: (val) => {
		if (!val) localStorage.removeItem(STORAGEKEY_TOKEN_ANONIMO);
		else localStorage.setItem(STORAGEKEY_TOKEN_ANONIMO, val);
	},

	setUrlEsApi: (val) => {
		if (!val) localStorage.removeItem(STORAGEKEY_URL_API_ES);
		else localStorage.setItem(STORAGEKEY_URL_API_ES, val);
	},

	setUrlNFeApi: (val) => {
		if (!val) localStorage.removeItem(STORAGEKEY_URL_API_NFE);
		else localStorage.setItem(STORAGEKEY_URL_API_NFE, val);
	},

	setUrlCrmApi: (val) => {
		if (!val) localStorage.removeItem(STORAGEKEY_URL_API_CRM);
		else localStorage.setItem(STORAGEKEY_URL_API_CRM, val);
	},

	setUrlLisApi: (val) => {
		if (!val) localStorage.removeItem(STORAGEKEY_URL_API_LIS);
		else localStorage.setItem(STORAGEKEY_URL_API_LIS, val);
	},

	setUrlFiscalApi: (val) => {
		if (!val) localStorage.removeItem(STORAGEKEY_URL_API_FISCAL);
		else localStorage.setItem(STORAGEKEY_URL_API_FISCAL, val);
	},

	setUrlFaturamentoApi: (val) => {
		if (!val) localStorage.removeItem(STORAGEKEY_URL_API_FATURAMENTO);
		else localStorage.setItem(STORAGEKEY_URL_API_FATURAMENTO, val);
	},

	setUrlFinanceiroApi: (val) => {
		if (!val) localStorage.removeItem(STORAGEKEY_URL_API_FINANCEIRO);
		else localStorage.setItem(STORAGEKEY_URL_API_FINANCEIRO, val);
	},

	setUrlSuprimentosApi: (val) => {
		if (!val) localStorage.removeItem(STORAGEKEY_URL_API_SUPRIMENTOS);
		else localStorage.setItem(STORAGEKEY_URL_API_SUPRIMENTOS, val);
	},

	setUrlImagensProdutosApi: (val) => {
		if (!val) localStorage.removeItem(STORAGEKEY_URL_API_IMAGENS);
		else localStorage.setItem(STORAGEKEY_URL_API_IMAGENS, val);
	},

	setUrlConnectHub: (val) => {
		if (!val) localStorage.removeItem(STORAGEKEY_URL_CONNECT_HUB);
		else localStorage.setItem(STORAGEKEY_URL_CONNECT_HUB, val);
	},

	reset: () => {
		localStorage.removeItem(STORAGEKEY_TOKEN);
		localStorage.removeItem(STORAGEKEY_URL_API_ES);
		localStorage.removeItem(STORAGEKEY_URL_API_NFE);
		localStorage.removeItem(STORAGEKEY_URL_API_CRM);
		localStorage.removeItem(STORAGEKEY_URL_API_FISCAL);
		localStorage.removeItem(STORAGEKEY_URL_API_FATURAMENTO);
		localStorage.removeItem(STORAGEKEY_URL_API_FINANCEIRO);
		localStorage.removeItem(STORAGEKEY_URL_API_LIS);
		localStorage.removeItem(STORAGEKEY_URL_API_SUPRIMENTOS);
		localStorage.removeItem(STORAGEKEY_URL_API_IMAGENS);
		localStorage.removeItem(STORAGEKEY_URL_CONNECT_HUB);
	},
};

export default apiConfig;
