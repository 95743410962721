import swal from './alerts';

export const baixarArquivoPdf = function (conteudoArquivo, nomeArquivo, abrirAutomaticamente, utilizaTerminalPayment) {
  const newBlob = new Blob([conteudoArquivo], { type: 'application/pdf' });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }
  var data = window.URL.createObjectURL(newBlob);

  if (abrirAutomaticamente) {
    let downloadWindow = window.open(data, '_blank');

    setTimeout(() => {
      if (!downloadWindow || downloadWindow.closed || typeof downloadWindow == 'undefined' || downloadWindow.outerHeight === 0) {
        swal.exibirMensagemAlerta(
          'Verifique se o seu navegador bloqueou o pop-up ou se possui algum bloqueador de anúncios que esteja bloqueando o acesso ao arquivo.',
          null,
          false,
        );
      }
    }, 100);
  } else {
    var link = document.createElement('a');
    link.href = data;
    link.download = nomeArquivo + '.pdf';
    link.click();
  }

  setTimeout(function () {
    window.URL.revokeObjectURL(data);
  }, 300);
};

export const baixarArquivoZip = function (conteudoArquivo, nomeArquivo) {
  const newBlob = new Blob([conteudoArquivo], {
    type: 'application/octet-stream',
  });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }
  var data = window.URL.createObjectURL(newBlob);
  var link = document.createElement('a');
  link.href = data;
  link.download = nomeArquivo + '.zip';
  link.click();
  setTimeout(function () {
    window.URL.revokeObjectURL(data);
  }, 100);
};

export const base64toBlob = function (b64Data, contentType, sliceSize) {
  contentType = '';
  sliceSize = 512;
  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const baixarArquivoExcel = function (arquivo) {
  var type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;';
  var blobFile = base64toBlob(arquivo.Content, type);

  if (typeof window.navigator.msSaveBlob !== 'undefined') window.navigator.msSaveBlob(blobFile, arquivo.Filename);

  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blobFile);
  link.download = arquivo.Filename;
  link.click();
};
