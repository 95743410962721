import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';

const getDefaultState = () => ({
	ItensRecebMercadorias: [],
	IdRecebimento: [],
});

const state = getDefaultState();

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
