const getUiStateDefaultState = () => {
  return {
    tituloPagina: 'INÍCIO',
    isLoading: false,
    quantidadeRequisicoes: 0,
  };
};

const state = getUiStateDefaultState();

const mutations = {
  reset(state) {
    Object.assign(state, getUiStateDefaultState());
  },

  showLoading(state) {
    state.isLoading = true;
  },

  hideLoading(state) {
    state.isLoading = false;
  },

  setQuantidadeRequisicoes(state, payload) {
    state.quantidadeRequisicoes = payload;
  },
};

const actions = {
  loadingController({ state, commit }, payload) {
    let quantidadeRequisicoes = state.quantidadeRequisicoes + payload;
    if (quantidadeRequisicoes < 0) quantidadeRequisicoes = 0;

    commit('setQuantidadeRequisicoes', quantidadeRequisicoes);

    state.quantidadeRequisicoes != 0 ? commit('showLoading') : commit('hideLoading');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
