import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import { createStore } from 'vuex-extensions';
import uiState from './modules/uiState';
import autenticacao from './modules/autenticacao/autenticacao';
import devolucao from './modules/devolucao/devolucao';
import balanco from './modules/balanco/balanco';
import importacao from './modules/produtos/importacao/importacao';
import imagens from './modules/produtos/imagens/imagens';
import localizacao from './modules/localizacao/localizacao';
import recebimento from './modules/recebimento/recebimento';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
	storage: window.sessionStorage,
	key: 'estoque-store',
	modules: ['autenticacao', 'devolucao', 'balanco', 'recebimento'],
});

export default createStore(Vuex.Store, {
	state: {},
	mutations: {},
	actions: {
		resetAllState: function () {
			this.reset();
		},
	},
	modules: {
		uiState,
		autenticacao,
		devolucao,
		balanco,
		importacao,
		localizacao,
		imagens,
		recebimento,
	},
	plugins: [vuexLocal.plugin],
});
