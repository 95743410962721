<template>
  <transition name="modal-fade">
    <!-- <div :class="['modal-backdrop', classesAdicionaisBackDrop]" @keydown.esc="handleClose" @click="handleClose"> -->
    <div :class="['modal-backdrop', classesAdicionaisBackDrop]" @keydown.esc="handleClose" @mousedown.self="handleClose($event)">
      <div
        :class="['modal m-3', { 'fit-content': !telaInteira }, classesAdicionaisModal]"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        tabindex="-1"
        style="overflow: hidden !important!"
        @click.stop=""
      >
        <header :class="['modal-header', classesAdicionaisHeader]">
          <slot name="header">
            <span class="text-uppercase" v-show="tituloModal">{{ tituloModal }}</span>
            <button type="button" id="lmxta-basemodal-btn-fechartopo" class="btn-close p-0 float-right" @click="handleClose">
              <i class="fas fa-times" />
            </button>
          </slot>
        </header>

        <template>
          <section :class="['modal-body only-overflow-y', classesAdicionaisBody]">
            <slot name="loading" />
            <slot name="body" />
          </section>
        </template>

        <footer :class="['modal-footer', classesAdicionaisFooter]">
          <slot name="footer">
            <div class="col-6">
              <button type="button" :id="idBotaoFechar" class="btn btn-secondary rounded" @click="handleClose"> Fechar </button>
            </div>
            <div class="col-6 text-right px-2">
              <button type="button" class="btn btn-primary rounded" :id="idBotaoSalvar" @click="salvar()"> Salvar </button>
            </div>
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'BaseModal',
    props: {
      tituloModal: {
        type: String,
        required: false,
      },

      telaInteira: {
        type: Boolean,
        required: false,
        default: false,
      },
      classesAdicionaisBackDrop: {
        type: String,
        required: false,
        default: '',
      },
      classesAdicionaisModal: {
        type: String,
        required: false,
        default: '',
      },
      classesAdicionaisHeader: {
        type: String,
        required: false,
        default: 'p-2 cabecalho-modal-customizado-lmx',
      },
      classesAdicionaisBody: {
        type: String,
        required: false,
        default: '',
      },
      classesAdicionaisFooter: {
        type: String,
        required: false,
        default: 'p-2',
      },
      idBotaoFechar: {
        type: String,
        required: false,
        default: 'lmxta-basemodal-btn-fechar',
      },
      idBotaoSalvar: {
        type: String,
        required: false,
        default: 'lmxta-basemodal-btn-salvar',
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      handleClose(event) {
        this.$emit('close');
      },
      salvar() {
        this.$emit('salvar');
      },
    },
  };
</script>

<style scoped>
  .cabecalho-modal-customizado-lmx {
    background-color: #5b2e90 !important;
    color: white !important;
    padding: 0.6rem !important;
  }

  .cabecalho-modal-customizado-lmx button {
    color: #fff !important;
  }

  .modal-content .no-padding {
    padding: 0rem !important;
  }

  .modal-dialog.modal-fluid {
    max-width: 90%;
    margin: 0.5rem auto;
  }

  .modal-body {
    overflow-y: auto;
  }

  .modal-fade-enter,
  .modal-fade-leave-active {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
  }

  .modal {
    background: #ffffff;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 2rem) !important;
  }

  .fit-content {
    height: fit-content !important;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    border: none;
    font-size: 20px;
    padding: 20px;
    cursor: pointer;
    font-weight: bold;
    background: transparent;
  }

  .overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .only-overflow-y {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .modal-size-importacao {
    padding: 0px !important;
    max-width: 80% !important;
  }

  .section-modal-importacao-produtos-style {
    display: flex !important;
    flex-direction: column !important;
    overflow: hidden !important;
    max-height: 100vh !important;
    max-width: 100vw !important;
    padding: 15px 0 15px 0 !important;
  }

  .imagem-modal-configuracoes-on-loading {
    min-height: 605px;
    padding: 0;
    overflow: auto !important;
  }
</style>
