import { ID_UNIDADE } from '@/core/enums/unidade-compra-venda.enum';

export const getters = {
	ehPrimeiroAcesso: (state) => {
		return (
			!state.Configuracoes.preferenciasEmpresa.Serie &&
			!state.Configuracoes.preferenciasEmpresa.CodigoNaturezaOperacao &&
			!state.Configuracoes.preferenciasEmpresa.IdDeposito
		);
	},

	obterPermissao: () => {
		return true;
	},

	baseUrlErp: (state) => {
		return state.urlBaseERP + '/gestor_web';
	},

	iniciado: (state) => {
		return state.iniciado;
	},

	obterNotaAssociada: (state) => (id) => {
		return state.NotasAssociadas.find((n) => n.Id == id);
	},

	obterDadosAdicionaisPorNota: (state) => {
		return state.Conferencia.DadosAdicionaisPorNota;
	},

	emitirNotasPorChaveOrigem: (state) => {
		return state.Configuracoes.preferenciasEmpresa.EmitirNotasPorChaveOrigem;
	},

	tipoArquivoReferenciaEntrada: (state) => {
		return state.tipoArquivoReferenciaEntrada;
	},

	listarNotasAssociadasDto: (_) => (notas) => {
		return notas.map((nota) => {
			return {
				...nota,
				Itens: nota.Itens.filter((item) => item.Quantidade > 0).map((item) => {
					return {
						...item,
						UsaUnidadeCompra: item.UnidadeSelecionada.Id == ID_UNIDADE.Compra,
						UnidadeSelecionada: item.UnidadeSelecionada.Descricao,
						UnidadeCompra: item.UnidadeCompra?.Descricao,
						UnidadeVenda: item.UnidadeVenda.Descricao,
					};
				}),
			};
		});
	},

	listarNotasAssociadasParaAssociacao: (state, getters) => {
		return getters.listarNotasAssociadasDto(state.NotasAssociadas);
	},

	listarNotasAssociadasParaConferencia: (state, getters) => {
		const notasConferencia = state.NotasAssociadasComItensSelecionados.length
			? state.NotasAssociadasComItensSelecionados
			: state.NotasAssociadas;

		return getters.listarNotasAssociadasDto(notasConferencia);
	},
};
