import apiClient from '../../../services/apiClient';
import apiConfig from '../../../services/apiConfig';
import dateHelper from '@/common/date';

const baseUrl = `${apiConfig.getUrlSuprimentosApi()}/api`;

const getDefaultState = () => ({
	listaDeBalancos: [],
	empresasPortal: [],
	empresaAtual: {},
	obterDadosIniciaisPainelBalancos: [],
	enumSituacaoBalanco: {
		Planejado: 1,
		EmAndamento: 2,
		Finalizado: 3,
	},
	dadosCadastroBalanco: {},
	filtros: {},
	produto: {},
	responseFinalizarSecoes: {},
	responseFiltrarBalancos: {},
	listaItensConferidos: [],
	listaSecaoEQuantidadeEstorno: [],
	parametroCasasDecimaisQuantidadeProduto: 2,
	permissoes: {},
});

const state = getDefaultState();

const mutations = {
	setListaDeBalancos(state, payload) {
		state.listaDeBalancos = payload;
	},
	setDataCadastroBalanco(state, payload) {
		state.dadosCadastroBalanco = payload;
	},
	setEmpresasPortal(state, payload) {
		state.empresasPortal = payload;
	},
	setEmpresaAtual(state, payload) {
		state.empresaAtual = payload;
	},
	setFiltrarBalancos(state, payload) {
		state.responseFiltrarBalancos = payload;
	},
	setProdutoPesquisado(state, payload) {
		state.produto = payload;
	},
	setFinalizarSecoesResponse(state, payload) {
		state.responseFinalizarSecoes = payload;
	},
	atualizarListaItensConferidos(state, payload) {
		state.listaItensConferidos = payload;
	},
	atualizarConferenciaItemSecao(state, { index, produto }) {
		state.listaItensConferidos.splice(index, 1, produto);
	},
	setListaSecaoEQuantidadeEstorno(state, payload) {
		state.listaSecaoEQuantidadeEstorno = payload;
	},
	setParametroCasasDecimaisQuantidadeProduto(state, payload) {
		state.parametroCasasDecimaisQuantidadeProduto = payload;
	},
	setPermissoes(state, payload) {
		state.permissoes = payload;
	},
};

const actions = {
	obterDadosIniciaisPainelBalancos() {
		return new Promise((resolve, reject) => {
			const url = apiConfig.getUrlSuprimentosApi() + '/api/Balanco/ObterDadosIniciaisPainelBalancos';

			apiClient
				.get(url)
				.then(({ data }) => resolve(data))
				.catch((erro) => reject(erro));
		});
	},
	obterDadosIniciaisCadastroBalancos() {
		return new Promise((resolve, reject) => {
			const url = apiConfig.getUrlSuprimentosApi() + '/api/Balanco/ObterDadosIniciaisCadastroBalancos';
			apiClient
				.get(url)
				.then(({ data }) => resolve(data))
				.catch((erro) => reject(erro));
		});
	},
	obterDadosParaAtualizarBalanco(_, { idBalanco }) {
		return new Promise((resolve, reject) => {
			const url = apiConfig.getUrlSuprimentosApi() + '/api//Balanco/ObterDadosParaAtualizarBalancos?idBalanco=' + idBalanco;
			apiClient
				.get(url)
				.then(({ data }) => resolve(data))
				.catch((erro) => reject(erro));
		});
	},
	obterDadosIniciaisParaAcompanhamento(_, { IdBalanco }) {
		return new Promise((resolve, reject) => {
			const url =
				apiConfig.getUrlSuprimentosApi() + '/api/Balanco/ObterDadosIniciaisParaAcompanhamento?IdBalanco=' + IdBalanco;
			apiClient
				.get(url)
				.then(({ data }) => resolve(data))
				.catch((erro) => reject(erro));
		});
	},
	cadastrarBalanco({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const url = apiConfig.getUrlSuprimentosApi() + '/api/Balanco/CadastrarBalanco';
			const body = JSON.stringify(payload);

			apiClient
				.post(url, body)
				.then(({ data }) => {
					commit('setDataCadastroBalanco', data);
					return resolve(data);
				})
				.catch((erro) => reject(erro));
		});
	},
	atualizarBalanco({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const url = apiConfig.getUrlSuprimentosApi() + '/api/Balanco/AtualizarBalanco';
			const body = JSON.stringify(payload);

			apiClient
				.post(url, body)
				.then(({ data }) => {
					commit('setDataCadastroBalanco', data);
					return resolve(data);
				})
				.catch((erro) => reject(erro));
		});
	},
	cancelarBalanco(_, payload) {
		return new Promise((resolve, reject) => {
			const url = apiConfig.getUrlSuprimentosApi() + '/api/Balanco/CancelarBalanco';
			const body = JSON.stringify(payload);

			apiClient
				.post(url, body)
				.then(({ data }) => resolve(data))
				.catch((erro) => reject(erro));
		});
	},
	validarCodigoDeBarras({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const url = apiConfig.getUrlSuprimentosApi() + '/api/Balanco/ValidarCodigoDeBarras';
			const body = JSON.stringify(payload);
			apiClient
				.post(url, body)
				.then(({ data }) => {
					commit('setProdutoPesquisado', data);
					return resolve(data);
				})
				.catch((erro) => reject(erro));
		});
	},
	finalizarExecucaoSecao({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const url = apiConfig.getUrlSuprimentosApi() + '/api/Balanco/FinalizarExecucaoSecaoBalanco';
			const body = JSON.stringify(payload);

			apiClient
				.post(url, body)
				.then(({ data }) => {
					const { status } = data;
					commit('setFinalizarSecoesResponse', status);
					return resolve(data);
				})
				.catch((erro) => reject(erro));
		});
	},
	filtrarBalancos({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const url = apiConfig.getUrlSuprimentosApi() + '/api//Balanco/FiltrarBalancos';
			const body = JSON.stringify(payload);

			apiClient
				.post(url, body)
				.then(({ data }) => {
					commit('setFiltrarBalancos', data);
					return resolve(data);
				})
				.catch((erro) => reject(erro));
		});
	},
	iniciarExecucaoSecaoBalanco(_, payload) {
		return new Promise((resolve, reject) => {
			const url = apiConfig.getUrlSuprimentosApi() + '/api/Balanco/IniciarExecucaoSecaoBalanco';
			const body = JSON.stringify(payload);

			apiClient
				.post(url, body)
				.then(({ data }) => resolve(data))
				.catch((erro) => reject(erro));
		});
	},
	cadastrarSecao(_, payload) {
		return new Promise((resolve, reject) => {
			const url = apiConfig.getUrlSuprimentosApi() + '/api/Balanco/CadastrarSecao';
			const body = JSON.stringify(payload);

			apiClient
				.post(url, body)
				.then(({ data }) => resolve(data))
				.catch((erro) => reject(erro));
		});
	},
	limparDadosConferenciaSecao(_, payload) {
		return new Promise((resolve, reject) => {
			const url = apiConfig.getUrlSuprimentosApi() + '/api//Balanco/LimparDadosConferenciaSecao';
			const body = JSON.stringify(payload);

			apiClient
				.post(url, body)
				.then(({ data }) => resolve(data))
				.catch((erro) => reject(erro));
		});
	},
	alterarQuantidadeProdutoSecao(_, payload) {
		return new Promise((resolve, reject) => {
			const url = apiConfig.getUrlSuprimentosApi() + '/api/Balanco/AlterarQuantidadeProdutoSecao';
			const body = JSON.stringify(payload);

			apiClient
				.post(url, body)
				.then(({ data }) => resolve(data))
				.catch((erro) => reject(erro));
		});
	},
	obterDadosIniciaisParaFinalizacao(_, { idBalanco }) {
		return new Promise((resolve, reject) => {
			const url =
				apiConfig.getUrlSuprimentosApi() + '/api/Balanco/ObterDadosIniciaisParaFinalizacao?IdBalanco=' + idBalanco;
			apiClient
				.get(url)
				.then(({ data }) => resolve(data))
				.catch((erro) => reject(erro));
		});
	},
	async finalizarBalanco({ dispatch, commit }, payload) {
		const url = `${apiConfig.getUrlSuprimentosApi()}/api/Balanco/FinalizarBalanco`;
		const { data } = await apiClient.post(url, JSON.stringify(payload));

		return data;
	},
	conferirExecucaoItemSecao(_, payload) {
		return new Promise((resolve, reject) => {
			const url = apiConfig.getUrlSuprimentosApi() + '/api/Balanco/ConferirExecucaoItemSecao';
			const body = JSON.stringify(payload);

			apiClient
				.post(url, body)
				.then(({ data }) => {
					resolve(data);
				})
				.catch((erro) => reject(erro));
		});
	},
	excluirConferenciaExecucaoItemSecao(_, payload) {
		return new Promise((resolve, reject) => {
			const url = apiConfig.getUrlSuprimentosApi() + '/api/Balanco/excluirConferenciaExecucaoItemSecao';
			const body = JSON.stringify(payload);

			apiClient
				.post(url, body)
				.then(({ data }) => resolve(data))
				.catch((erro) => reject(erro));
		});
	},
	atualizarListaItensConferidos({ commit }, payload) {
		commit('atualizarListaItensConferidos', payload);
	},
	atualizarConferenciaItemSecao({ commit }, payload) {
		commit('atualizarConferenciaItemSecao', payload);
		return new Promise((resolve, reject) => {
			const url = apiConfig.getUrlSuprimentosApi() + '/api/Balanco/AtualizarConferenciaItemSecao';
			const body = JSON.stringify(payload.commandItem);

			apiClient
				.post(url, body)
				.then(({ data }) => {
					resolve(data);
				})
				.catch((erro) => reject(erro));
		});
	},
	listarCriticasSecao(_, payload) {
		return new Promise((resolve, reject) => {
			const body = JSON.stringify(payload);
			const url = apiConfig.getUrlSuprimentosApi() + '/api/Balanco/ListarCriticasSecao';
			apiClient
				.post(url, body)
				.then(({ data }) => resolve(data))
				.catch((erro) => reject(erro));
		});
	},
	listarItensConferenciaSecao(_, payload) {
		return new Promise((resolve, reject) => {
			const body = JSON.stringify(payload);
			const url = apiConfig.getUrlSuprimentosApi() + '/api/Balanco/ListarItensConferenciaSecao';
			apiClient
				.post(url, body)
				.then(({ data }) => resolve(data))
				.catch((erro) => reject(erro));
		});
	},
	listarSecoesBalancoParaExecucao(_, payload) {
		return new Promise((resolve, reject) => {
			const body = JSON.stringify(payload);
			const url = apiConfig.getUrlSuprimentosApi() + '/api/Balanco/ListarSecoesBalancoParaExecucao';
			apiClient
				.post(url, body)
				.then(({ data }) => resolve(data))
				.catch((erro) => reject(erro));
		});
	},
	validarArquivoUploadBalanco(_, payload) {
		return new Promise((resolve, reject) => {
			const body = JSON.stringify(payload);
			const url = apiConfig.getUrlSuprimentosApi() + '/api/Balanco/ValidarArquivoUploadBalanco';
			apiClient
				.post(url, body)
				.then(({ data }) => resolve(data))
				.catch((erro) => reject(erro));
		});
	},

	ObterSecaoEQuantidaEstorno({ commit }, { IdBalanco }) {
		return new Promise((resolve, reject) => {
			const url = apiConfig.getUrlSuprimentosApi() + '/api/Balanco/ObterSecaoEQuantidaEstorno?IdBalanco=' + IdBalanco;
			apiClient
				.get(url)
				.then(({ data }) => {
					commit('setListaSecaoEQuantidadeEstorno', data.ListaSecaoEQuantidadeEstorno);
					return resolve(data);
				})
				.catch((erro) => reject(erro));
		});
	},

	EstornarBalanco(_, idBalanco) {
		return new Promise((resolve, reject) => {
			const url = apiConfig.getUrlSuprimentosApi() + '/api/Balanco/EstornarBalanco?IdBalanco=' + idBalanco;
			apiClient
				.get(url)
				.then(({ data }) => resolve(data))
				.catch((erro) => reject(erro));
		});
	},

	ObterDadosConferenciaBalanco(_, { idBalanco }) {
		return new Promise((resolve, reject) => {
			const url = apiConfig.getUrlSuprimentosApi() + '/api/Balanco/ObterDadosConferenciaBalanco?IdBalanco=' + idBalanco;
			apiClient
				.get(url)
				.then(({ data }) => resolve(data))
				.catch((erro) => reject(erro));
		});
	},
	obterImagemProduto(_, { codigoProduto }) {
		return new Promise((resolve, reject) => {
			const url =
				apiConfig.getUrlImagensProdutoApi() + '/api/produtos/imagens/principal/' + codigoProduto;
			apiClient
				.get(url, {responseType: 'blob'})
				.then(({ data }) => resolve(data))
				.catch((erro) => reject(erro));
		});
	},
};

const getters = {
	listaDeBalancosNaoFinalizados(state) {
		return (
			state.listaDeBalancos &&
			state.listaDeBalancos.filter((balanco) => {
				return balanco.IdSituacao != state.enumSituacaoBalanco.Finalizado;
			})
		);
	},
	listaItensConferidos(state) {
		return state.listaItensConferidos?.map((produto) => {
			return {
				...produto,
				Quantidade: parseFloat(produto.Quantidade),
				DataFabricacaoFormatada: produto.DataFabricacao ? dateHelper.toStringPtBr(produto.DataFabricacao) : '-',
				DataVencimentoFormatada: produto.DataVencimento ? dateHelper.toStringPtBr(produto.DataVencimento) : '-',
				ExibirImagem: produto.ExibirImagem || false,
				ExibirLotes: produto.ExibirLotes ?? produto.Lotes.length > 1,
				Lotes: produto.Lotes.map((lote) => {
					return {
						...lote,
						DataFabricacaoFormatada: lote.DataFabricacao ? dateHelper.toStringPtBr(lote.DataFabricacao) : '-',
						DataVencimentoFormatada: lote.DataVencimento ? dateHelper.toStringPtBr(lote.DataVencimento) : '-',
					}
				}),
			};
		});
	},
	getPermissoes(state) {
		return state.permissoes;
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
