/* eslint-disable prettier/prettier */
import apiClient from '../../../services/apiClient';
import apiConfig from '../../../services/apiConfig';
import { truncateDecimals } from '@/common/number';
import { ID_UNIDADE } from '@/core/enums/unidade-compra-venda.enum';

export const actions = {
  async iniciar({ commit }) {
    commit('reset');
  },

  async iniciarNovaDevolucao({ commit }) {
    commit('resetDevolucao');
  },

  atualizarFornecedor({ commit }, payload) {
    commit('atualizarFornecedor', payload);
  },

  atualizarTipoDevolucao({ commit }, payload) {
    commit('atualizarTipoDevolucao', payload);
  },

  atualizarAssociacao({ commit }, payload) {
    commit('atualizarAssociacao', payload);
  },

  atualizarNotasConferencia({ commit }, payload) {
    commit('atualizarNotasConferencia', payload);
  },
  atualizarNotasFinalizadas({ commit }, payload) {
    commit('finalizado', payload);
  },
  atualizarNotaAutorizada({ commit }, payload) {
    commit('autorizado', payload);
  },
  finalizar({ commit, state }, payload) {
    let request = {
      IdFornecedor: state.Identificacao.Fornecedor.Codigo,
      IdDeposito: state.Conferencia.Deposito.Id,
      CodigoNaturezaOperacao: state.Conferencia.NaturezaOperacao.Codigo,
      Devolucoes: state.Conferencia.Referenciados,
      DadosAdicionais: state.Conferencia.DadosAdicionais,
    };
    return new Promise((resolve, reject) => {
      const url = apiConfig.getUrlEsApi() + '/api/DevolucaoEntrada/Finalizar';

      apiClient
        .post(url, request)
        .then((response) => {
          if (!response) {
            return resolve(response);
          }

          commit('finalizado', response.data);

          return resolve(response);
        })
        .catch((err) => {
          let erros = [];
          let result = [];

          if (err && err.response && err.response.data) {
            result = err.response.data;
          }

          if (Array.isArray(result)) {
            erros = result.map(erro => {
              if (erro != "")
                return erro;
            });
          }

          if (erros.length == 0) {
            erros.push('Ocorreu uma falha ao gerar sua nota.');
          }

          console.log(err);
          return reject(erros);
        });
    });
  },

  conferir({ commit, getters, state }) {
    let notasConferencias = getters.listarNotasAssociadasParaConferencia;

    let request = {
      IdFornecedor: state.Identificacao.Fornecedor.Codigo,
      DevolucoesReferenciadas: notasConferencias,
    };

    const url = apiConfig.getUrlEsApi() + '/api/DevolucaoEntrada/Conferir';

    return new Promise((resolve, reject) => {
      apiClient
        .post(url, request)
        .then((response) => {
          if (!response) {
            return resolve(response);
          }

          if (response.data.HasNotifications) {

            let erros = [];
            erros.push('Não é possível importar produtos com serial por essa rotina. Por favor usar a rotina antiga.');
            return reject(erros);
          }

          commit('atualizarNotasConferencia', response.data.Result);
          commit('removerTodasNotasAssociadasComItemSelecionado')
          return resolve(response);
        })
        .catch((err) => {
          console.log(err);
          return reject(err);
        });
    });
  },

  conferido({ commit, state }, payload) {
    commit('conferido', payload);
  },

  obterDadosIniciais({ commit, getters, rootGetters }) {
    commit('atualizarStatusDadosIniciais', false);

    return new Promise((resolve, reject) => {
      const urlRequest = apiConfig.getUrlEsApi() + '/api/DevolucaoEntrada/ObterParametrizacaoInicial';

      apiClient
        .get(urlRequest)
        .then((response) => {
          if (!response) {
            return resolve(response);
          }

          commit('atualizarListas', response.data.ListasGerais);
          commit('atualizarPreferenciasEmpresa', response.data.Preferencias);
          commit('atualizarParametrosEmpresa', response.data.PesquisaProdutoAceitaCodigoAlfanumerico);
          commit('atualizarConfiguracaoEmpresa', response.data.ConfiguracaoEmpresa);
          commit('atualizarParametrosLote', response.data);
          commit('atualizarStatusDadosIniciais', true);

          return resolve(response);
        })
        .catch((err) => {
          console.log(err);
          return reject(err);
        });
    });
  },

  salvarPreferencias({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const funcValidacaoPreferencias = async function (dadosParaValidacao) {
        return await dispatch('validarPreferenciasEmpresa', dadosParaValidacao);
      };

      const errosValidacao = funcValidacaoPreferencias(payload);

      if (errosValidacao.length > 0) {
        return reject(errosValidacao);
      }

      const urlRequest = apiConfig.getUrlEsApi() + '/api/DevolucaoEntrada/SalvarPreferencias';

      apiClient
        .post(urlRequest, payload)
        .then(async (response) => {
          await commit('atualizarPreferenciasEmpresa', payload);
          //await commit("salvarValidacaoPreferenciasEmpresa", true);
          return resolve();
        })
        .catch((error) => {
          console.log(error);
          return reject([error.data]);
        });
    });
  },

  validarPreferenciasEmpresa({ state, commit }, payload) {
    let mensagensErro = [];

    let preferencias = payload || state.Configuracoes.preferenciasEmpresa;

    if (!preferencias.CodigoNaturezaOperacao) mensagensErro.push('Natureza de Operação não informada.');

    if (!preferencias.Serie) mensagensErro.push('Série não informada.');

    if (!preferencias.IdDeposito) mensagensErro.push('Depósito não informado.');

    commit('salvarValidacaoPreferenciasEmpresa', mensagensErro.length === 0);
    return mensagensErro;
  },

  listarProdutos({ commit, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      const urlRequest = apiConfig.getUrlEsApi() + '/api/DevolucaoEntrada/ListarProdutos';

      apiClient
        .get(urlRequest, { params: payload })
        .then((response) => {
          return resolve(response);
        })
        .catch((err) => {
          console.log(err);
          return reject(err);
        });
    });
  },

  listarProdutosReferenciaEntradas({ commit, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      const urlRequest = apiConfig.getUrlEsApi() + '/api/DevolucaoEntrada/ListarProdutosReferenciaEntradas';

      apiClient
        .post(urlRequest, payload)
        .then((response) => {
          return resolve(response);
        })
        .catch((err) => {
          console.log(err);
          return reject(err);
        });
    });
  },

  obterItensUploadArquivo({ state, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const urlRequest = apiConfig.getUrlEsApi() + '/api/DevolucaoEntrada/ListarProdutosUploadArquivoReferenciaEntradas';

      apiClient
        .post(urlRequest, payload, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
          return resolve(response);
        })
        .catch((err) => {
          console.log(err);
          return reject(err);
        });
    });
  },

  obterItensUploadArquivoPorTipo({ state, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const urlRequest = apiConfig.getUrlEsApi() + '/api/DevolucaoEntrada/ListarProdutosUploadArquivoChaveEntradas';

      apiClient
        .post(urlRequest, payload, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
          commit('atualizarTipoArquivoReferenciaEntrada', response.data.TipoArquivoReferenciaEntrada);
          return resolve(response);
        })
        .catch((err) => {
          console.log(err);
          return reject(err);
        });
    });
  },

  ObterProdutoReferenciaEntradas({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const urlRequest = apiConfig.getUrlEsApi() + '/api/DevolucaoEntrada/ObterProdutoReferenciaEntradas';

      apiClient
        .post(urlRequest, payload)
        .then((response) => {
          let produtoAssociacaoManual = response.data;
          if (!produtoAssociacaoManual || !produtoAssociacaoManual.CodigoProduto) return resolve(response);

          produtoAssociacaoManual.Quantidade = payload.Quantidade;

          dispatch('inserirProdutoAssociacaoManual', produtoAssociacaoManual);
          return resolve(response);
        })
        .catch((err) => {
          console.log(err);
          return reject(err);
        });
    });
  },

  inserirProdutoAssociacaoManual({ commit, state }, payload) {
    this._vm.$set(payload, 'Selecionado', false);

    Object.defineProperty(payload, 'QuantidadeSelecionada', {
      get: function () {
        return this.EntradasDisponiveis.reduce((acumulado, item) => acumulado + item.QuantidadeSelecionada, 0);
      },
    });
    Object.defineProperty(payload, 'QuantidadeRestante', {
      get: function () {
        return this.Quantidade - this.QuantidadeSelecionada;
      },
    });
    Object.defineProperty(payload, 'ValorTotal', {
      get: function () {
        return this.EntradasDisponiveis.filter((e) => e.Selecionado).reduce((acumulado, item) => acumulado + item.PrecoTotal, 0);
      },
    });

    Object.defineProperty(payload, 'CasasDecimais', {
      get: function () {
        return Math.max(this.EntradasDisponiveis.filter((e) => e.Selecionado).map((item) => item.UnidadeSelecionada.CasasDecimais));
      },
    });

    payload.EntradasDisponiveis?.forEach((nota) => {
      this._vm.$set(nota, 'QuantidadeSelecionada', 0);
      this._vm.$set(nota, 'PrecoTotal', 0);

      if (nota.UnidadeCompra) {
        nota.UnidadeCompra = {
          Id: ID_UNIDADE.Compra,
          Descricao: nota.UnidadeCompra,
          PrecoUnitario: nota.PrecoUnitario * nota.FatorConversao,
          QuantidadePendente: truncateDecimals(nota.QuantidadePendente / nota.FatorConversao, nota.UnidadeCompraCasasDecimais || 0),
          CasasDecimais: nota.UnidadeCompraCasasDecimais || 0,
        };
      }
      
      nota.UnidadeVenda = {
        Id: ID_UNIDADE.Venda,
        Descricao: nota.UnidadeVenda,
        PrecoUnitario: nota.PrecoUnitario,
        QuantidadePendente: truncateDecimals(nota.QuantidadePendente, nota.UnidadeVendaCasasDecimais || 0),
        CasasDecimais: nota.UnidadeVendaCasasDecimais || 0,
      };

      nota.UnidadeSelecionada = nota.UnidadeVenda;

      Object.defineProperty(nota, 'Selecionado', {
        get: function () {
          return this.QuantidadeSelecionada > 0;
        },
      });

      nota.AlterarQuantidade = function (quantidade) {
        if (quantidade < 0) quantidade = 0;
        if (quantidade > this.QuantidadePendente) quantidade = this.QuantidadePendente;

        this.QuantidadeSelecionada = quantidade;
        this.PrecoTotal = this.PrecoUnitario * this.QuantidadeSelecionada;
      };
    });

    commit('inserirProdutoAssociacaoManual', payload);
    commit('inserirProduto', payload);
  },

  associarManualmente({ commit, state }, payload) {
    let request = {
      IdFornecedor: state.Identificacao.Fornecedor.Codigo,
      ItensDesassociados: payload.itensDesassociados,
      Referenciados: state.NotasAssociadas,
      Chave: payload.nota.ChaveDeAcesso,
      Numero: payload.nota.Documento,
      Serie: payload.nota.Serie,
    };

    return new Promise((resolve, reject) => {
      const urlRequest = `${apiConfig.getUrlEsApi()}/api/DevolucaoEntrada/AssociarManualmente`;

      apiClient
        .post(urlRequest, request)
        .then((response) => {
          return resolve(response);
        })
        .catch((err) => {
          console.log(err);
          return reject(err);
        });
    });
  },

  associarAutomaticamente({ commit, getters, state }) {
    var itensDesassociados = state.ItensSemVinculo;
    if (state.ProdutosAssociacaoManual.length)
      itensDesassociados = state.ProdutosAssociacaoManual.filter((p) => p.QuantidadeRestante != 0).map((p) => ({
        CodigoProduto: p.CodigoProduto,
        Quantidade: p.QuantidadeRestante,
      }));

    let request = {
      IdFornecedor: state.Identificacao.Fornecedor.Codigo,
      ItensDesassociados: itensDesassociados,
      Referenciados: getters.listarNotasAssociadasParaAssociacao,
    };

    return new Promise((resolve, reject) => {
      const urlRequest = `${apiConfig.getUrlEsApi()}/api/DevolucaoEntrada/AssociarAutomaticamente`;

      apiClient
        .post(urlRequest, request)
        .then((response) => {
          if (!response.data) {
            response.data = { error: '' };
            return resolve(response);
          }

          var notasProdutos = response.data.DevolucoesReferenciadas.flatMap((nota) =>
            nota.Itens.map((produto) => ({ produto, nota })),
          );

          notasProdutos.forEach((notaProduto) => {
            var produtoState = state.ProdutosAssociacaoManual.find((p) => p.CodigoProduto == notaProduto.produto.CodigoProduto);
            var nota = produtoState.EntradasDisponiveis.find(
              (n) => n.Id == notaProduto.nota.Id && n.Transacao == notaProduto.produto.Transacao,
            );
            if (!nota) return;

            nota.AlterarQuantidade(notaProduto.produto.Quantidade);
          });

          return resolve(response);
        })
        .catch((err) => {
          console.log(err);
          return reject(err);
        });
    });
  },

  ObterNotaEntrada({ state }, payload) {
    var params = {
      Numero: payload.Numero,
      Serie: payload.Serie,
      Chave: payload.Chave,
      IdFornecedor: state.Identificacao.Fornecedor.Codigo,
      PesquisarProdutosPorSerial: state.Configuracoes.PesquisaProdutoPorSerial,
    };

    return new Promise((resolve, reject) => {
      const urlRequest = `${apiConfig.getUrlEsApi()}/api/DevolucaoEntrada/ObterNotaEntrada`;

      apiClient
        .get(urlRequest, { params })
        .then((response) => {
          return resolve(response);
        })
        .catch((err) => {
          console.log(err);
          return reject(err);
        });
    });
  },

  obterDanfeNFe({ commit }, idNFe) {
    return new Promise((resolve, reject) => {
      const urlRequest = apiConfig.getUrlNFeApi() + `/api/nfe/${idNFe}/danfe`;

      apiClient
        .get(urlRequest, {
          headers: {
            Accept: 'application/pdf',
          },
          responseType: 'blob',
        })
        .then((response) => {
          return resolve(response);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },

  enviarEmailNFe({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const urlRequest = apiConfig.getUrlNFeApi() + '/api/nfe/' + payload.IdNFe + '/enviar_email';

      apiClient
        .post(urlRequest, { ListaEmails: payload.ListaEmails })
        .then((response) => {
          return resolve(response);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },

  autorizarNFe({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const urlRequest = apiConfig.getUrlNFeApi() + '/api/nfe/autorizar';
      apiClient
        .post(urlRequest, payload)
        .then((response) => {
          if (!response) return resolve(response);

          commit('autorizado', response.data);

          return resolve(response);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },

  inserirItemSemVinculo({ commit }, payload) {
    commit('inserirItemSemVinculo', payload);
    commit('inserirProduto', payload);
  },

  inserirItemNotaEntrada({ commit }, payload) {
    payload.forEach((item) => {
      commit('inserirProduto', item);
    });
  },

  atualizarItemSemVinculo({ commit }, payload) {
    commit('atualizarItemSemVinculo', payload);
  },

  atualizarMultiplosItensSemVinculo({ commit }, payload) {
    commit('atualizarMultiplosItensSemVinculo', payload);
  },

  atualizarTodosItensSemVinculo({ commit }, payload) {
    commit('atualizarTodosItensSemVinculo', payload);
  },

  removerItemSemVinculo({ commit }, payload) {
    commit('removerItemSemVinculo', payload);
  },

  removerTodosItensSemVinculo({ commit }) {
    commit('removerTodosItensSemVinculo');
  },

  inserirNotaAssociada({ commit }, payload) {
    commit('inserirNotaAssociada', payload);
  },

  selecionarTodosItensAssociados({ commit }, payload) {
    commit('selecionarTodosItensAssociados', payload);
  },

  removerTodasNotasAssociadas({ commit }) {
    commit('removerTodasNotasAssociadas');
  },

  removerNotaAssociada({ commit }, payload) {
    commit('removerNotaAssociada', payload);
  },

  removerItemNotaAssociada({ commit }, payload) {
    commit('removerItemNotaAssociada', payload);
  },

  atualizarItemNotaAssociada({ commit }, payload) {
    commit('atualizarItemNotaAssociada', payload);
  },
  
  atualizarListaItensSelecionados({ commit }, payload) {
    commit('atualizarListaItensSelecionados', payload);
  },

  atualizarNotasAssociadasComItemsSelecionados({ commit }, payload) {
    commit('atualizarNotasAssociadasComItemsSelecionados', payload);
  },

  removerTodasNotasAssociadasComItemSelecionado({ commit }, payload) {
    commit('removerTodasNotasAssociadasComItemSelecionado')
  },

  alterarProdutoNotaAssociada({ commit, getters, state }) {
    if (!state.ProdutosAssociacaoManual.length) return;

    var produtosNotas = state.ProdutosAssociacaoManual.flatMap((produto) =>
      produto.EntradasDisponiveis.filter((nota) => nota.Selecionado && nota.QuantidadeSelecionada > 0).map((nota) => ({
        produto,
        nota,
      })),
    );

    produtosNotas.forEach((produtoNota) => {
      var nota = getters.obterNotaAssociada(produtoNota.nota.Id);

      if (!nota) {
        const notaAssociada = {
          Id: produtoNota.nota.Id,
          Numero: produtoNota.nota.Numero,
          Serie: produtoNota.nota.Serie,
          Chave: produtoNota.nota.Chave,
          DataEmissao: produtoNota.nota.DataEmissao,
          Itens: [],
        };
        commit('inserirNotaAssociada', notaAssociada);
        nota = notaAssociada;
      }

      const produtoAssociado = {
        Transacao: produtoNota.nota.Transacao,
        CodigoProduto: produtoNota.produto.CodigoProduto,
        PrecoUnitario: produtoNota.nota.PrecoUnitario,
        Quantidade: produtoNota.nota.QuantidadeSelecionada,
        QuantidadeLimite: produtoNota.nota.QuantidadePendente,
        Serial: produtoNota.nota.Serial,
        Lote: produtoNota.nota.Lote,
        LoteDataFabricacao: produtoNota.nota.LoteDataFabricacao,
        LoteDataVencimento: produtoNota.nota.LoteDataVencimento,
        UnidadeCompra: produtoNota.nota.UnidadeCompra,
        UnidadeVenda: produtoNota.nota.UnidadeVenda,
        FatorConversao: produtoNota.nota.FatorConversao,
        UnidadeSelecionada: produtoNota.nota.UnidadeSelecionada,
      };

      commit('adicionarItemNotaAssociada', { produto: produtoAssociado, nota: nota });
    });
  },
  removerProdutosAssociacaoManual({ commit }, payload) {
    payload.forEach((p) => {
      commit('removerProdutoAssociacaoManual', p);
    });
  },
  async recarregarProdutosAssociacaoManual({ rootState, dispatch, commit, state }) {
    let produtos = [...state.ProdutosAssociacaoManual];
    if (!produtos?.length) return;

    commit('removerTodosProdutosAssociacaoManual');

    for (const p of produtos) {
      let parametrosObterItem = {
        IdEmpresa: rootState.autenticacao.empresa.Id,
        IdFornecedor: state.Identificacao.Fornecedor.Codigo,
        Codigo: p.CodigoProduto,
        Quantidade: p.Quantidade,
        AceitaCodigoAlfanumerico: state.Configuracoes.PesquisaProdutoAceitaCodigoAlfanumerico,
      };
      await dispatch('ObterProdutoReferenciaEntradas', parametrosObterItem);
    }
  },
  filtrarProdutos({ commit }, payload) {
    commit('filtrarProdutos', payload);
  },
  listaDeProdutos({ commit }) {
    commit('listaProdutos');
  },
  obterDadosComplementares({ _ }, payload) {
    return new Promise((resolve, reject) => {
      const urlRequest = apiConfig.getUrlEsApi() + '/api/NotaSimplificada/ObterDadosIniciaisDadosComplementares';

      apiClient
        .post(urlRequest, payload)
        .then((response) => {
          return resolve(response);
        })
        .catch((err) => {
          console.log(err);
          return reject(err);
        });
    });
  },
  atualizarDadosAdicionaisNotasConferencia({ commit }, payload) {
    commit('atualizarDadosAdicionaisNotasConferencia', payload);
  },
};
