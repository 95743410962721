import apiClient from '../../../services/apiClient';
import apiConfig from '../../../services/apiConfig';

export const actions = {
	obterDadosRecebimento({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const urlRequest =
				apiConfig.getUrlEsApi() +
				'/api/RecebimentoMercadoriasCompra/ObterItensConferenciaOnlineRecebimentoMercadorias?idRecebimentoMercadoria=' +
				payload;

			apiClient
				.get(urlRequest)
				.then((response) => {
					commit('atualizarItensRecebMercadorias', response.data?.ItensRecebMercadorias);
					return resolve(response);
				})
				.catch((err) => {
					console.log(err);
					return reject(err);
				});
		});
	},
	conferirItem({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const urlRequest = apiConfig.getUrlEsApi() + '/api/RecebimentoMercadoriasCompra/ConferirItemRecebimentoMercadorias';

			apiClient
				.post(urlRequest, payload)
				.then((response) => {
					return resolve(response);
				})
				.catch((err) => {
					console.log(err);
					return reject(err);
				});
		});
	},
	excluirItemRecebimentoMercadorias(_, payload) {
		return new Promise((resolve, reject) => {
			const urlRequest = apiConfig.getUrlEsApi() + '/api/RecebimentoMercadoriasCompra/ExcluirItemRecebimentoMercadorias';

			apiClient
				.post(urlRequest, payload)
				.then(({ data }) => resolve(data))
				.catch((erro) => reject(erro));
		});
	},
	finalizarConferenciaOnline(_, payload) {
		return new Promise((resolve, reject) => {
			const urlRequest =
				apiConfig.getUrlEsApi() +
				'/api/RecebimentoMercadoriasCompra/FinalizarConferenciaOnline?idRecebimentoMercadoria=' +
				payload;

			apiClient
				.post(urlRequest)
				.then(({ data }) => resolve(data))
				.catch((erro) => reject(erro));
		});
	},
	retomarConferenciaOnline({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const urlRequest =
				apiConfig.getUrlEsApi() +
				'/api/RecebimentoMercadoriasCompra/RefazerConferenciaOnline?idRecebimentoMercadoria=' +
				payload;

			apiClient
				.post(urlRequest)
				.then((response) => {
					commit('atualizarItensRecebMercadorias', response.data?.ItensRecebMercadorias);
					return resolve(response);
				})
				.catch((erro) => reject(erro));
		});
	},
	atualizarListaItensConferidos({ commit }, payload) {
		commit('atualizarItensRecebMercadorias', payload);
	},
};
